<template>
    <div class="wrapper-container" :style="{ height: '100vh'}">

      <Loader :show="true" v-if="!uuid && Object.keys(errorResponse).length < 1" />

      <div class="app-container" v-else>
        <div v-if="uuid" class="main-header">
          <router-link :to="{name: 'home'}" class="main-logo"></router-link>
          <button v-on:click="uploadDirectly" class="upload-button-main">Upload File</button>
        </div>

        <Toolset v-if="!openedWindowRoute && !openRoute" ref="toolset" />
        <Panes v-if="!openedWindowRoute && !openRoute && uuid" />

        <MainModal v-if="!openedWindowRoute" />

        <LadderView v-bind:protocol="false" v-if="!openedWindowRoute && !openedFlow && openRoute" />

        <VoipFlow v-if="!openedWindowRoute && openedFlow" v-bind:fileName="uploadedFile.fileName" />

        <EntireWindow v-if="openedWindowRoute" />
      </div>
    </div>
  </template>

  <script>
  import Loader from '@/components/widgets/Loader.vue';
  import Toolset from '../Toolset.vue';
  import Panes from '../datapanes/Panes.vue';
  import MainModal from '../dialogs/MainModal';
  import EntireWindow from '../dialogs/EntireWindow';
  import LadderView from '../dialogs/LadderView';
  import VoipFlow from '../dialogs/VoipFlow';
  import { mapState, mapActions } from 'vuex';

  export default {
    name: 'Dashboard',
    components: {
      Loader,
      Toolset,
      Panes,
      MainModal,
      EntireWindow,
      LadderView,
      VoipFlow
    },
    computed: {
      ...mapState({
        currentWindow: state => state.views.currentWindow,
        flowDiagram: state => state.views.diagramFlowOpened,
        ladderDiagram: state => state.views.diagramOpened,
        uuid: state => state.packets.uuid,
        errorResponse: state => state.packets.errorResponse
      }),
      openedWindowRoute() {
        return this.$router.history.current.params.window;
      },
      openedFlow() {
        let voipFlow = this.$router.history.current.path.split(`${this.$router.history.current.params.uuid}/`)[1];

        voipFlow = voipFlow && voipFlow.split("?")[0];

        let openedFlow = voipFlow === 'voip_flow';

        return openedFlow;
      },
      openRoute() {
        let newTab = (this.$router.history.current.name === 'window') ||
          (this.$router.history.current.name === 'ladder') ||
          (this.$router.history.current.name === 'flow');
        return newTab;
      },
      routeName() {
        return this.$route.name;
      }
    },
    methods: {
      ...mapActions({
        getExistingFile: 'packets/getExistingFile'
      }),
      uploadDirectly() {
        this.$refs.toolset.$refs.toolsetheader.$refs.upload.click();
      }
    },

    watch: {
      openedFlow(newValue) {
        if (newValue) {
          // document.querySelector('html').style.height = '100vh';
          document.querySelector('html').style.overflow = 'hidden';
          document.querySelector('body').style.overflow = 'auto';
        }
      },
      // uuid(newValue) { //oldValue
      //   console.log(newValue);
      //   newValue && this.$router.push(newValue);
      //   // (newValue !== oldValue && !newValue) && this.$router.push('/');
      // },
      errorResponse() {
        this.$router.push('/error');
      }
    },
    async created() {
      if (
          !this.uuid &&
          !this.openRoute &&
          this.$router.history.current.params.uuid
      ) {
        this.getExistingFile(this.$router.history.current.params.uuid);
      }
      if (
          (this.uuid && this.$router.history.current.params.uuid) &&
          (this.uuid !== this.$router.history.current.params.uuid)
      ) {
        this.getExistingFile(this.$router.history.current.params.uuid);
      }
    },
    mounted() {
      if (this.openedFlow) {
        // document.querySelector('html').style.height = '100vh';
        document.querySelector('html').style.overflow = 'hidden';
        document.querySelector('body').style.overflow = 'auto';
      }
      // window.onbeforeunload = function () {
      //   window.localStorage.clear();
      //   window.sessionStorage.clear();
      // };
    }
  };
  </script>

  <style>

  .wrapper-container {
    height: 100%;
  }

  .about-section h2{
    margin-top: 100px;
    font-size: 28px;
    font-weight: 300;
    color: #5C696A;
    margin-bottom: 52px;
  }
  .about-section p{
    max-width: 670px;
    margin: 0 auto;
    font-weight: 400;
    font-size: 16px;
    color: #5C696A;
    text-align: center;
    margin-bottom: 50px;
  }
  .homepage-logo {
    text-align: center;
    margin-top: 100px;
  }
  .homepage-logo .header-logo {
    height: 45px;
  }
  .homepage-logo .main-title {
    font-weight: 300;
    font-size: 32px;
    color: #5c696a;
    margin: 0px;
  }
  @media (max-width: 850px){
    .homepage-logo{
      margin-top: 80px;
    }
  }
  @media (max-width: 450px){
    .about-section h2{
      margin-top: 70px;
      margin-bottom: 30px;
    }
    .about-section p{
      margin-bottom: 153px;
    }
    .homepage-logo{
      margin-top: 60px;
    }
  }
  .container.narrow {
    max-width: 970px;
    margin: 0 auto;
  }
  .flex {
    display: flex;
  }
  .items-center {
    align-items: center;
  }
  .justify-between {
    justify-content: space-between;
  }
  .mr-10 {
    margin-right: 10px;
  }
  .mr-20 {
    margin-right: 20px;
  }

  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

  .app-container {
    height: 100%;
    background: #F7F8F9;
    /* position: absolute; */
  }

  .main-header {
    background: #F7F8F9;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .main-header .main-logo {
    background: url('../../assets/logo/3-01.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 120px;
    height: 60px;
    margin-left: 20px;
  }

  .main-header .upload-button-main {
    cursor: pointer;
    border: 1px solid #03C0FC;
    color: #03C0FC;
    border-radius: 6px;
    background: #fff;
    padding: .8em 2.1em;
    margin-right: 20px;
  }

  .main-header #file-main {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  /* body::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #b5b7b7;
  }

  ::-webkit-scrollbar-thumb {
    background: #606060;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */

  button:focus {
    outline: none;
  }
  </style>
