<template>
    <div
      class="filter-button graphs"
      v-on:click="openTab(tabClass)"
      v-bind:class="{
        active: tabIsOpened,
        unactive: !tabIsOpened
      }"
    >
        <button class="graphs-button">
            Graphs
            <span class="icon"></span>
        </button>

        <OptionsList
            v-bind:optionsList="graphsList"
        />
    </div>
</template>

<script>
import OptionsList from './shared-ui/OptionsList.vue'

export default {
  name: 'Graphs',
  components: {
    OptionsList
  },
  props: {
    currentTab: String,
    openTab: Function
  },
  data () {
      return {
          tabClass: 'graphs',
          openedOption: -1,
          graphsList: [
            {label: 'Graphs', value: 'GraphsDialog'},
            {label: 'All Traffic', value: 'GraphsStats', query: ''},
            // {label: 'All Traffic', value: 'GraphsStats', query: 'interval=0.1'},
            {label: 'Current Display Filter', value: 'GraphsStats', query: 'filter='},
          ]
      }
  },
  computed: {
    tabIsOpened: function () {
      return this.currentTab === this.tabClass;
    }
  }
}
</script>

<style scoped>
.graphs .graphs-button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    /* width: 93px; */
    border-radius: 6px;
}
</style>
