// import axios from 'axios'

// initial state
const state = () => ({
  currentDialog: null,
  currentWindow: {},
  diagramOpened: {switcher: false, searchQuery: ''},
  diagramFlowOpened: {switcher: false, searchQuery: '', data: null},
})

// getters
const getters = {
  loadedDialog: (state) => {
    return state.currentDialog
  }
}

// actions
const actions = {

  toggleDialog ({ commit, state }, currentDialog) {

    console.log(state, currentDialog.value);
    
    commit('setCurrentDialog', {})

    let value = currentDialog ? currentDialog.value : null;
    //just currentDialog.value

    commit('setCurrentDialog', {value});
    commit('packets/setDialogContent', {value}, {root: true});
  },

  toggleWindow ({ commit, rootState }, currentWindow) {

    // console.log(state, currentWindow);

    const savedContent = {...rootState.packets.dialogContent};
    // const savedDialog = {...rootState.packets.currentDialog};
    // console.log(savedContent);

    commit('setCurrentWindow', {});
    // commit('setCurrentDialog', {});
    commit('packets/setDialogContent', {}, {root: true});

    let value = currentWindow ? currentWindow.value : null; 
    //just currentDialog.value

    commit('setCurrentWindow', {value,  mode: currentWindow.mode, data: currentWindow.data});
    // commit('setCurrentDialog', {value: savedDialog});
    commit('packets/setDialogContent', {content: savedContent}, {root: true});
  },

  toggleDiagram ({commit}, {switcher, searchQuery}) {
    commit('setDiagramOpened', {switcher, searchQuery});
  },

  toggleFlowDiagram ({state, commit}, {switcher, searchQuery, data}) {
    let dataChecked = (!data && state.diagramFlowOpened.data) ? 
      state.diagramFlowOpened.data : data;
    commit('setFlowDiagramOpened', {switcher, searchQuery, data: dataChecked});
  }

}

// mutations
const mutations = {

  setCurrentDialog (state, {value}) {
    state.currentDialog = value ? {value} : null;
    console.log(state.currentDialog);
  },

  setDiagramOpened (state, {switcher, searchQuery}) {
    state.diagramOpened = {switcher, searchQuery};
  },

  setFlowDiagramOpened (state, {switcher, searchQuery, data}) {
    state.diagramFlowOpened = {switcher, searchQuery, data};
  },

  setCurrentWindow (state, {value, mode, data}) {
    console.log(state.currentWindow, value)
    state.currentWindow = value ? {value, mode, data} : {};
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}