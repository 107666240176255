<template>
  <div>
    <h2 class="upload-text">Upload Your PCAP File</h2>
    <div class="upload-section"
        :class="[dragAndDrop && 'upload-section-dragover']" 
         @dragover="dragover"
         @dragleave="dragleave"
         @drop="drop">
      <img src="../../assets/download_cloud.svg" alt="">
      <p>Drag & Drop PCAP File Here</p>
      <p class="or-text">OR</p>
      <label for="file-upload" class="select-file-btn">
          <img src="../../assets/file.svg" alt="">
          <span>Select a File</span>
        <input id="file-upload" type="file" accept=".pcap" ref="file" @change="uploadDirect" />
      </label>
      <p v-if="error" class="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data: () => {
    return {
      files: null,
      dragAndDrop: false,
      error: ''
    }
  },
  methods: {
    ...mapActions({
      uploadFile: 'packets/uploadAndGetUUID',
    }),
    uploadDirect(ev) {
      this.uploadFile(ev.target.files[0]);
      this.dragleave();
    },
    dragover(event) {
      this.error = ''
      event.preventDefault();
      this.dragAndDrop = true;
    },
    dragleave() { 
      this.dragAndDrop = false;
    },
    drop(event) {
      this.error = ''
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      if (file.type === 'application/vnd.tcpdump.pcap' || file.name.split('.')[1] === 'pcap') {
        this.uploadFile(file);
        this.dragleave();
      } else {
        this.dragAndDrop = false
        this.error = 'pcap files only'
      }
    }
  }
}
</script>

<style scoped>
@media (max-width: 450px) {
  div .upload-text {
    margin-top: 70px;
  }
}

#file-upload {
  display: none;
}

.upload-text {
  margin-top: 100px;
  font-size: 28px;
  font-weight: 300;
  color: #5C696A;
  margin-bottom: 30px;
}

.upload-section {
  margin: 0 auto;
  text-align: center;
  padding: 52px;
  border: 2px dashed #C3D8DB;
  border-radius: 10px;
}

.upload-section-dragover {
  background-color: #91a7a8;
}

.upload-section-dragover p {
  color: white !important;
}

.upload-section p {
  font-size: 16px;
  font-weight: 400;
  color: #5C696A;
  margin-top: 20px;
}

.upload-section p.or-text {
  font-weight: 200;
  margin-top: 57px;
}

.select-file-btn {
  max-width: 172px;
  background: #8CC541;
  color: white;
  padding: 16px 30px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-content: center;
  margin: 0 auto;
  font-size: 16px;
}

.select-file-btn img {
  height: 14px;
  margin-right: 12px;
}

.error {
  color: red !important
}

input {
  display: none;
}
</style>