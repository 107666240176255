<template>
  <div class="header-wrapper">
    <div class="container">
      <div class="header-container">
        <div class="left-part">
          <router-link :to="{ name: 'home' }">
            <img class="mr-20 logo" src="../../assets/logo/logo.svg" alt="" />
          </router-link>
          <p class="header-title">The easiest way to share SIP PCAP</p>
        </div>
        <div class="right-part">
          <ul class="menu-list">
            <router-link :to="{ name: 'home' }">
              <li><a href="#" @click="closeOverlay()">Home</a></li>
            </router-link>
            <router-link :to="{ name: 'aboutUs' }">
            <li>
              <a href="#" @click="closeOverlay()">About Us</a>
            </li>
            </router-link>
            <router-link :to="{ name: 'contact' }">
              <li><a href="#" @click="closeOverlay()">Contact</a></li>
            </router-link>
          </ul>
          <a href="http://www.denovolab.com" class="sip-switch">Need a SIP switch?</a>
          <a href="#" class="hamburger-menu">
            <img class="hamburger" src="../../assets/hamburger.svg" alt="" @click="openOverlay()" />
            <img class="close hidden" src="../../assets/close-btn.svg" alt="" @click="closeOverlay()" />
          </a>
        </div>
      </div>
    </div>
    <div class="mobile-menu">
      <ul>
        <router-link :to="{ name: 'home' }">
          <li><a href="#" @click="closeOverlay()">Home</a></li>
        </router-link>
        <router-link :to="{ name: 'aboutUs' }">
          <li><a href="#" @click="closeOverlay()">About Us</a></li>
        </router-link>
        <router-link :to="{ name: 'contact' }">
          <li><a href="#" @click="closeOverlay()">Contact</a></li>
        </router-link>
        <hr>
        <li>
          <a class="sip-switch" href="http://www.denovolab.com" target="_blank">Need a SIP switch?</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  methods: {
    openOverlay() {
      document.querySelector('.mobile-menu').classList.add('open')
      document.querySelector('.hamburger').classList.add('hidden')
      document.querySelector('.close').classList.remove('hidden')
      document.querySelector("body").style.overflow = "hidden"
    },
    closeOverlay() {
      document.querySelector('.mobile-menu').classList.remove('open')
      document.querySelector('.close').classList.add('hidden')
      document.querySelector('.hamburger').classList.remove('hidden')
      document.querySelector("body").style.overflow = "auto"
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.logo {
  max-width: 76px;
  width: 100%;
  display: block;
}

.hidden {
  display: none;
}

.close {
  height: 25px;
}

.hamburger-menu {
  display: none;
}

.mobile-menu {
  position: absolute;
  top: 0;
  bottom: 0;
  top: 65px;
  background: white;
  position: absolute;
  width: 100%;
  height: 0vh;
  opacity: 0;
  transition: all 0.2s ease;
  pointer-events: none;
  z-index: 9;
}

.mobile-menu hr {
  border: none;
  background: #C3D8DB;
  height: 1px;
  width: 180px;
  margin-bottom: 35px;
}

.mobile-menu .router-link-exact-active a {
  font-weight: 500;
  color: #8CC541;
}

.mobile-menu .sip-switch {
  padding-top: 35px;
}

.mobile-menu .contact {
  margin-bottom: 70px;
}

.mobile-menu.open {
  height: 100vh;
  opacity: 1;
  pointer-events: all;
}

.mobile-menu ul {
  padding-top: 40px;
  padding: 0;
  list-style: none;
}

.mobile-menu ul li {
  display: block;
  text-align: center;
  margin-bottom: 40px;
}

.mobile-menu ul li a {
  text-decoration: none;
  color: #5C696A;
  font-weight: 300;
  font-size: 16px;
}

@media (max-width: 850px) {
  .hamburger-menu {
    display: block;
  }

  .header-container .right-part .menu-list,
  .header-container .right-part .sip-switch {
    display: none;
  }
}

@media (max-width: 450px) {
  .header-title {
    display: none;
  }
}

.hamburger-menu img {
  width: 30px;
}

.header-container {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-title {
  font-weight: 200;
  font-size: 14px;
  color: #5c696a;
}

.left-part {
  display: flex;
  align-items: center;
}

.right-part {
  display: flex;
  align-content: center;
}

.right-part .sip-switch {
  height: 30px;
  display: flex;
  align-items: center;
  border-left: 1px solid #c3d8db;
  font-size: 16px;
  font-weight: 300;
  color: #5c696a;
  text-decoration: none;
  padding-left: 30px;
}

.right-part .menu-list {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.right-part .menu-list li {
  margin-right: 50px;
}

.right-part .menu-list li a {
  font-size: 14px;
  font-weight: 300;
  color: #5c696a;
  text-decoration: none;
}

.right-part .menu-list li.router-link-exact-active a,
.right-part .menu-list li:hover a {
  color: #8cc541;
  font-weight: 500;
}

hr {
  margin: auto;
}
</style>
