<template>
  <div class="filter-button exports">
    <button class="exports-button">
      Exports
      <!-- <span class="icon"></span> -->
    </button>
    <a class="download-link" :href="downloadFile" download></a>
  </div>
</template>

<script>
// import OptionsList from './shared-ui/OptionsList.vue'
import { mapState } from 'vuex';
import { api_pcap } from '@/services/api';

export default {
  name: 'Exports',
  components: {
    // OptionsList
  },
  props: {
    // currentTab: String,
    // openTab: Function
  },
  data() {
    return {
      tabClass: 'exports',
    }
  },
  computed: {
    downloadFile () {
      return `${api_pcap}/${this.uuid}/download`
    },
    // tabIsOpened: function () {
    //   return this.currentTab === this.tabClass;
    // }
    ...mapState({
      uuid: state => state.packets.uuid
    }),
  }
}
</script>

<style scoped>
.exports .exports-button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  /* width: 90px; */
  border-radius: 6px;
}

.filter-button.exports .download-link {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
</style>
