import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';

import VeeValidate, { Validator } from 'vee-validate';

import Highcharts from 'highcharts';
import ExportingHighcharts from 'highcharts/modules/exporting';
import boost from 'highcharts/modules/boost';
import Stock from 'highcharts/modules/stock';
import HighchartsVue from 'highcharts-vue';

import Toast, { TYPE } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
  hideProgressBar: true,
  icon: false,
  toastDefaults: {
    // ToastOptions object for each type of toast
    [TYPE.ERROR]: {
        timeout: 5000
    },
    [TYPE.DEFAULT]: {
        timeout: 2000
    }    
 }
};

boost(Highcharts);
Stock(Highcharts);
ExportingHighcharts(Highcharts);
Vue.use(HighchartsVue);
// Vue.use(require('moment'));
Vue.use(VeeValidate);
Vue.use(Toast, options);

const dictionary = {
  en: {
    messages: {
      required: (fieldName) => `${fieldName} is a required field`,
      email: 'email must be a valid email',
      numeric: 'phone number must contain only numbers',
    },
    attributes: {
      firstName: 'first name',
      lastName: 'last name'
    }
  }
};

Validator.localize(dictionary)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
