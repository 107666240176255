<template>
    <div v-if="show" class="loading-animated"></div>
</template>

<script>
export default {
    name: 'Loader',
    props: {
       show: Boolean
    }
}
</script>

<style scoped>
.loading-animated {
  background: url('../../assets/loading.gif');
  width: 70px;
  height: 70px;
  background-size: contain;
  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
}
</style>