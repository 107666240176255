import axios from 'axios'
import router from '@/router';

const state = () => ({
  uploadedFile: {
    fileSize: '',
    numberOfPackets: '',
    fileName: '',
  },
  uuid: '',
  frame: '',
  filterFiled: '',
  filterRange: '',
  packetDetails: [],
  packetBytes: '',
  packetList: [],
  traffic: [],
  trafficInterval: [],
  geoIpMap: [],
  fullPacketList: [],
  dialogContent : {},
  dialogInternal: {},
  selectedPacket: 0,
  localFile: {},
  dialogLoading: '',
  listLoading: '',
  packetDetailsLoading: '',
  bytesLoading: '',
  errorResponse: {}
})
const apiUrl = process.env.VUE_APP_API;

const apiUrls = {
  upload: `${apiUrl}/upload`,
  existingFiles: `${apiUrl}`,
  packetList: (uuid) => `${apiUrl}/${uuid}/packet_list`,
  fileInfo: (uuid) => `${apiUrl}/${uuid}/info`,
  packetDetails: (uuid, selected) => `${apiUrl}/${uuid}/${selected}/packet_details`,
  packetBytes: (uuid, selected) => `${apiUrl}/${uuid}/${selected}/packet_bytes`,

  ProtocolHierarchyStats: (uuid) => `${apiUrl}/${uuid}/get_protocol_hierarcy`,
  NetworkEndpoints: (uuid) => `${apiUrl}/${uuid}/endpoints`,
  ProtocolConversations:  (uuid) => `${apiUrl}/${uuid}/protocols`,
  RTPStreams: (uuid) => `${apiUrl}/${uuid}/get_rtp_streams`,
  FollowUDPStream: (uuid) => `${apiUrl}/${uuid}/see_sip_conversation`,
  dialogInternal: {
    FollowUDPStream: (uuid) => `${apiUrl}/${uuid}/get_hex_dump`,
    RTPStreams: (uuid) => `${apiUrl}/${uuid}/get_audio_links`,
    AudioFlow: (uuid) => `${apiUrl}/${uuid}/get_audio_links`,
    SIPStats: (uuid) => `${apiUrl}/${uuid}/get_sip_stat`,
    // GraphsStats: (uuid) => `${apiUrl}/${uuid}/rtp_data?interval=0.1`,
    GraphsStats: (uuid) => `${apiUrl}/${uuid}/rtp_data`,
  },
  VoIPCalls:  (uuid) => `${apiUrl}/${uuid}/get_voip_calls`,
  GeoIPWorldMap:  (uuid) => `${apiUrl}/${uuid}/info`, // REPLACE TO REAL ONE
  PacketLengths:  (uuid) => `${apiUrl}/${uuid}/get_packet_size_stat`,
  DNSActivity:  (uuid) => `${apiUrl}/${uuid}/`,
  HTTPAnalysis:  (uuid) => `${apiUrl}/${uuid}/`,
  WirelessNetworks:  (uuid) => `${apiUrl}/${uuid}/`,
  ThreatAssesment:  (uuid) => `${apiUrl}/${uuid}/`,
  ZeekLogs:  (uuid) => `${apiUrl}/${uuid}/`,
  LeftPaneDialog: (uuid) => `${apiUrl}/${uuid}/packet_list`,

  GraphsStats: (uuid) => `${apiUrl}/${uuid}/rtp_data`,
  GraphsStatsInterval: (uuid) => `${apiUrl}/${uuid}/rtp_data?interval=0.1`,
  SIPStats: (uuid) => `${apiUrl}/${uuid}/get_sip_stat`,

  geoIpMap: (uuid) => `${apiUrl}/${uuid}/geoIpMap`

}

const urls = apiUrls;

// getters
const getters = {
  getTraffic: (state) => {
    if (state.uploadedFile?.numberOfPackets / state.uploadedFile?.captureDuration < 1) return state.traffic
    // if (state.packetList.length < state.traffic.length) return state.traffic
    return state.trafficInterval
  },
  uploadedFileFormat: (state) => {
    let uploaded = state.uploadedFile;

    uploaded && Reflect.deleteProperty(uploaded, 'captureComment');

    return {
      ...uploaded,
      fileSize: (uploaded && uploaded.fileSize) ? uploaded.fileSize : '',

      numberOfPackets: (uploaded && uploaded.numberOfPackets) ?
        `${uploaded.numberOfPackets} packets` : '',

      fileName: (uploaded && uploaded.fileName) ?
        `${
          uploaded.fileName
            .substring(uploaded.fileName.lastIndexOf("/") + 1,
              uploaded.length)
        }` : '',
    }
  },
  getPacketListOrderBy: (state) => (orderBy) => {
    let temp = [...state.packetList]
    return orderBy === '' || orderBy === 'asc' ? temp.sort((a, b) => +a.no - +b.no) : temp.sort((a, b) => +b.no - +a.no);
  },
  getSelectedPacketNum: (state) => state.selectedPacket,
  packetsFrequency: (state) => {

    let list = state.packetList;

    const filteredPackets = (list && list.length > 0) && list.map(fr => {
      return {
        time: `${fr.time.split('.')[0]}.${fr.time.split('.')[1].slice(-3)}`,
        counter: fr.length//list.filter(frq => frq.length === fr.length).length
      }
    })//.filter((v,i,a)=>a.findIndex(t=>(t.length === v.length))===i);

    return filteredPackets;
  },
  fullPacketListFrequency: (state) => {

    let list = state.fullPacketList;

    const filteredPackets = (list && list.length > 0) && list.map(fr => {
      return {
        time: `${fr.time.split('.')[0]}.${fr.time.split('.')[1].slice(-3)}`,
        counter: fr.length//list.filter(frq => frq.length === fr.length).length
      }
    })//.filter((v,i,a)=>a.findIndex(t=>(t.length === v.length))===i);

    return filteredPackets;
  },

  internalDialogFormat: (state) => {

    let intCont = state.dialogInternal && Object.entries(state.dialogInternal),
      parts = (intCont && intCont[0]) && intCont[0][1];

    let everyRow = parts && parts.match(/.{1,72}/g);

    return everyRow && everyRow.map(er =>
      `${er.slice(0, 5)} 
          ${String.fromCharCode(160)}
          ${er.slice(5, 48)}
          ${String.fromCharCode(160)}
          ${er.slice(48)}`);
  }
}

// actions
const actions = {

  setUUID ({ commit }, uuid) {
    commit('setUUID', { uuid })
  },

  async uploadAndGetUUID({commit, dispatch}, file) {
    let formData = new FormData();
    formData.append('file', file);
    commit('saveFileLocally', file);
    dispatch('removeDataBeforeLoad');
    commit('setUUID', {});
    // commit('setSelectedPacket', 0);
    await axios.post(urls.upload, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(res => {
        commit('setUUID', {uuid: res.data.id})
        router.push('/dashboard/' + res.data.id)
      })
      .catch((e) => {
        let error = e;
        commit('setUUID', {uuid: ''});
        commit('setError', {status: true, statusCode: error && error.statusCode});
      });
  },

  getExistingFile({commit, dispatch}, uuid) {
    // commit('setUUID', {})
    dispatch('removeDataBeforeLoad');
    axios.get(`${urls.existingFiles}`)
      .then(res => {
          let requiredFile = res.data.find(exfile => exfile.id === uuid);
          commit('setUUID', {uuid: requiredFile.id});
        }
      ).catch((e) => {
      if (e) {
        commit('setUUID', {uuid: '', });
        commit('setError', {status: true, statusCode: 'file with this ID is not found'})
      }
    });
  },

  loadTraffic ({ commit }, uuid) {
    axios.get(urls.GraphsStats(uuid))
      .then(response => {
        commit('setLoadTraffic', response.data)
      })
      .catch(e => {
        if (e) {
          commit('setUUID', {uuid: '', });
          commit('setError', {status: true, statusCode: 'file with this ID is not found'})
        }
      })
    axios.get(urls.GraphsStatsInterval(uuid))
      .then(response => {
        commit('setLoadTrafficInterval', response.data)
      })
      .catch(e => {
        if (e) {
          commit('setUUID', {uuid: '', });
          commit('setError', {status: true, statusCode: '500'});
        }
      })
  },

  loadGeoIpMap ({ state, commit }) {
    axios.get(urls.geoIpMap(state.uuid))
      .then(response => {
        commit('setGeoIpMap', response.data);
      })
      .catch(e => {
        if (e) {
          commit('setUUID', {uuid: '', });
          commit('setError', {status: true, statusCode: '500'});
        }
      })
  },

  emptifyError({commit}) {
    commit('setError', {});
  },

  getFileInfo({commit, state}) {
    commit('setUploadedFile', {})
    state.uuid && axios.get(`${urls.fileInfo(state.uuid)}`)
      .then(res => commit('setUploadedFile', {fileInfo: res.data})
      ).catch(() =>
        commit('setUploadedFile', {fileInfo: {}}));
  },

  getPacketsList({state, commit}, {filter, name = null, timeDisplay, calls}) {
    const savedContent = state.packetList;
    if (name) {
      commit(name, filter);
    }
    let filterProtocol = [state.filterFiled, state.filterRange].filter(item => item !== '').join(" and ")

    if (filter && !filterProtocol.includes(filter)) {
      filterProtocol = filterProtocol ? `${filter} and ${filterProtocol}` : filter
    }
    commit('setListLoading', 'loading');
    state.uuid &&
    axios.get(`${urls.packetList(state.uuid) }${`?filter_protocol=${filterProtocol}`}${timeDisplay ? `&time_display=${timeDisplay}` : ''}${(calls || (calls === 0)) ? `&calls=${calls}` : ''}`)
      .then(
        res => {
          if (res.data && res.data !== "No packets were found matching the current filter" && res.data.length > 0) {
            commit('setPacketsList', {packetList: res.data})
          } else {
            commit('setDefaultData');
          }
          commit('setListLoading', '');
        }
      ).catch(() => {
      commit('setPacketsList', {packetList: savedContent});
      commit('setListLoading', '');
    });

  },

  getPacketDetails({commit, state}, selected) {

    console.log(selected);

    const savedContent = state.packetDetails;

    commit('setPacketsDetails', []);
    commit('setPacketDetailsLoading', 'loading');

    state.uuid && axios.get(`${urls.packetDetails(state.uuid, selected)}`)
      .then(res => {
          commit('setPacketsDetails', {packetDetails: res.data});
          commit('setPacketDetailsLoading', '');
        }
      ).catch(() =>
      {
        commit('setPacketsDetails', {packetDetails: savedContent});
        commit('setPacketDetailsLoading', '');
      });

  },

  getPacketBytes({commit, state}, selected) {

    console.log(selected, 'bytes');

    const savedContent = state.packetBytes;

    commit('setPacketBytes', '');
    commit('setBytesLoading', 'loading');

    state.uuid && axios.get(`${urls.packetBytes(state.uuid, selected)}`)
      .then(res => {
          commit('setPacketBytes', {packetBytes: res.data});
          commit('setBytesLoading', '');
        }
      ).catch(() =>
      {
        commit('setPacketBytes', {packetBytes: savedContent});
        commit('setBytesLoading', '');
      });

  },

  getSelectedPacket({commit}, selected) {
    console.log(selected);
    selected && commit('setSelectedPacket', selected);
  },

  loadDialogData ({ commit, state }, dialog) {
    console.log('dialog', dialog)

    const savedContent = {...state.dialogContent};

    commit('setDialogContent', {});
    commit('setDialogLoading', 'loading');

    dialog && axios.get(`${urls[dialog.value](state.uuid)}${dialog.query ? `?${dialog.query}` : ''}`)
      .then(res =>
        {
          commit('setDialogContent', {content: {[dialog.value]: res.data, isLadder: dialog.isLadder}});
          commit('setDialogLoading', '');
        }
      ).catch(() =>
        {
          commit('setDialogContent', {content: savedContent});
          commit('setDialogLoading', '');
        }
      );

    dialog && commit('views/setCurrentDialog', dialog, { root: true });
  },

  dialogInternalRequest ({commit, state}, dialog) {

    const savedContent = state.dialogInternal;

    axios.get(urls.dialogInternal[dialog](state.uuid)).then(res =>
      commit('setDialogInternal', {dialogInternal: res.data.data ? res.data.data : res.data})
    ).catch(() =>
      commit('setDialogInternal', {dialogInternal: savedContent}));

  },

  removeDataBeforeLoad({ commit }) {
    // commit('setUUID', {});
    commit('setLoadTraffic', []);
    commit('setLoadTrafficInterval', []);
    commit('setSelectedPacket', 0);
    commit('setDialogContent', {});
    commit('setDialogInternal', {});
    commit('saveFileLocally', {});
    commit('setDialogLoading', '');
    commit('setListLoading', '');
    commit('setPacketDetailsLoading', '');
    commit('setBytesLoading', '');
    commit('setFilterField', '');
    commit('setFilterRange', '');
  },

  removeUploadedPacket({ commit }) {
    commit('setUUID', {})
    commit('setUploadedFile', {})
    commit('setPacketsList', [])
    commit('setPacketsDetails', [])
    commit('setPacketBytes', '');
    commit('setSelectedPacket', 0);
    commit('setDialogContent', {});
    commit('setDialogInternal', {});
    commit('saveFileLocally', {});
    commit('setDialogLoading', '');
    commit('setListLoading', '');
    commit('setPacketDetailsLoading', '');
    commit('setBytesLoading', '');
    commit('views/setCurrentDialog', {}, {root: true});
    commit('views/setCurrentWindow', {}, {root: true});
    commit('views/setDiagramOpened', {}, {root: true});
  }
}

// mutations
const mutations = {
  setDialogContent (state, { content }) {
    console.log(state.dialogContent, content);
    state.dialogContent = content ? {...content, isLadder: content.isLadder} : {};
  },
  setDialogLoading(state, status) {
    state.dialogLoading = status;
  },
  setPacketsList (state, {packetList}) {
    state.selectedPacket = packetList[0].no;
    state.packetList = packetList;
    state.fullPacketList = (state.fullPacketList.length <= 0) ? packetList : state.fullPacketList;
  },
  setDefaultData(state) {
    state.selectedPacket = 0;
    state.packetList = [];
    state.packetDetails = [];
    state.packetBytes = [];
  },
  setListLoading(state, status) {
    state.listLoading = status;
  },
  setUUID(state, {uuid}) {
    state.uuid = uuid;
  },
  setUploadedFile (state, {fileInfo}) {
    state.uploadedFile = fileInfo;
  },
  setPacketsDetails(state, {packetDetails}) {
    state.packetDetails = packetDetails;
  },
  setPacketDetailsLoading(state, status) {
    state.packetDetailsLoading = status;
  },
  setSelectedPacket(state, selected) {
    state.selectedPacket = selected;
  },
  setPacketBytes(state, {packetBytes}) {
    state.packetBytes = packetBytes && packetBytes.data;
  },
  setBytesLoading(state, status) {
    state.bytesLoading = status;
  },
  setDialogInternal(state, dialogInternal) {
    state.dialogInternal = dialogInternal;
  },
  saveFileLocally(state, file) {
    state.localFile = file;
  },
  setError(state, error) {
    state.errorResponse = error;
  },
  setLoadTraffic(state, traffic) {
    state.traffic = traffic;
  },
  setLoadTrafficInterval(state, traffic) {
    state.trafficInterval = traffic;
  },
  setFilterField(state, filter) {
    state.filterFiled = filter;
  },
  setFilterRange(state, range) {
    state.filterRange = range;
  },
  setGeoIpMap(state, data) {
    state.geoIpMap = data;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
