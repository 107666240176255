<template>
  <div class="range-filter">
    <Transition>
      <div class="range-filter-timecodes" v-if="showTimeRange">{{ timeRange }}</div>
    </Transition>
    <div class="filter-button range-filter">
      <highcharts
          :constructorType="'stockChart'"
          class="hc"
          :options="chartOptions"
          ref="chart" />
      <div class="range-mask"
        :style="{ left: `calc(${start}px)`, right: `calc(${end}px)`, width: `calc(${fullWidth - (start + end)}px)` }">
      </div>
      <div class="range-filter-controller">
        <div class="button-container end" :style="{ left: `${start}px` }">
          <span></span>
          <button type="button" @mousedown="md($event)" @touchstart="md($event)" class="range-filter-start start"></button>
        </div>
        <div class="button-container start" :style="{ right: `${end}px` }">
          <span></span>
          <button type="button" @mousedown="md($event)" @touchstart="md($event)" class="range-filter-end end"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import { mapActions, mapGetters, mapState } from 'vuex';
import { formatTimeMSs } from '@/plugins/format';

export default {
  name: 'RangeFilter',
  data() {
    return {
      showTimeRange: false,
      range: '',
      dir: '',
      x: 0,
      start: 0,
      end: 0,
      fullWidth: 0,
      width: 188,
      height: 36,
      chartOptions: {
        credits: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        chart: {
          type: 'area',
          width: 192,
          height: 36,
          spacingBottom: 1,
          spacingTop: 0,
          spacingLeft: 7,
          spacingRight: 0,
        },
        scrollbar: {
          enabled: false
        },
        navigator: {
          enabled: false
        },
        legend: {
          enabled: false,
        },
        rangeSelector: {
          enabled: false,
          inputEnabled: false,
        },
        yAxis: {
          visible: false,
        },
        xAxis: {
          crosshair: false,
          visible: false,
        },
        plotOptions: {
          area: {
            marker: {
              enabled: false
            },
            lineWidth: 0.5,
            states: {
              hover: {
                enabled: false
              }
            }
          }
        },
        exporting: {
          enabled: false
        },
        series: [
          {
            type: 'area',
            fillColor:'rgb(85,157,212)',
            data: ''
          }
        ]
      },
    }
  },
  computed: {
    timeRange () {
      return `${formatTimeMSs(this.startTimeRange.toFixed(1))} - ${formatTimeMSs(this.endTimeRange.toFixed(1))}`
    },
    startRange: function () {
      let rounded = (this.start * 100) / this.fullWidth;
      return rounded.toFixed(0);
    },
    endRange: function () {
      let rounded = this.end / (this.fullWidth);
      return rounded.toFixed(2);
    },
    startTimeRange: function () {
      return ((this.maxTime * this.startRange) / 100);
    },
    endTimeRange: function () {
      return this.maxTime - (this.endRange * this.maxTime);
    },
    step: function () {
      console.log('step: ', this.maxTime / (this.fullWidth * 100))
        return (this.fullWidth) / this.maxTime ;
    },
    ...mapState({
      uploadedFile: state => state.packets.uploadedFile,
      traffic: state => state.packets.traffic,
      trafficInterval: state => state.packets.trafficInterval
    }),
    ...mapGetters('packets', {
      dataSet: 'packetsFrequency',
      getTraffic: 'getTraffic'
    }),
    maxTime() {
      return this.uploadedFile && parseFloat(this.uploadedFile.captureDuration);
    }
  },
  watch: {
    getTraffic(newValue, oldValue) {
      // (newValue > 0) ? this.drawFrequencies() : d3.selectAll("svg > *").remove();
      // (!newValue) && d3.selectAll("svg > *").remove();
      d3.selectAll("#frequency-data g").remove();
      newValue && this.drawFrequencies();
      (oldValue && !newValue) && d3.selectAll("#frequency-data g").remove();
    },
    // released(newValue, oldValue) {
    //   console.log(newValue, oldValue, newValue === oldValue)
    //   if (newValue !== oldValue) {
    //       this.filterTimeSequence();
    //   }
    // }
  },
  methods: {
    md(ev) {
      let dir = ev.target.className.split(" ")[1];
      this.dir = dir ? dir : this.dir;
      this.showTimeRange = true
      document.addEventListener("mousemove", this.mm);
      document.addEventListener("mouseup", this.mu);
      document.addEventListener("touchmove", this.tm);
      document.addEventListener("touchend", this.mu);
    },
    mu() {
      this.dir = ''
      this.showTimeRange = false
      this.filterTimeSequence();
      document.removeEventListener("mousemove", this.mm)
      document.removeEventListener("mouseup", this.mu)
      document.removeEventListener("touchmove", this.tm)
      document.removeEventListener("touchend", this.mu)
    },
    mm(ev) {
      if (this.dir === 'start') {
        let bounds = this.range.getBoundingClientRect();
        let pointerX = (ev.clientX - bounds.left - 6)
        if (pointerX >= 0 && pointerX <= (this.range.clientWidth - 5)) {
          if (this.end >= 0) {
            if (pointerX < (this.fullWidth - this.end)) {
              this.start = pointerX;
            }
          } else {
            this.start = pointerX;
          }
        }
      }
      else if (this.dir === 'end') {
        let bounds = this.range.getBoundingClientRect();
        let pointerX = this.range.clientWidth - (ev.clientX - bounds.left) - 8;
        if ((pointerX <= (this.range.clientWidth) && pointerX >= 0)) {
          if (this.start > 0) {
            if (pointerX < (this.fullWidth - this.start)) {
              this.end = pointerX;
            }
          } else {
            this.end = pointerX;
          }
        }
      }
    },
    tm() { },
    ...mapActions({
      loadPackets: 'packets/getPacketsList',
      loadTraffic: 'packets/loadTraffic'
    }),
    filterTimeSequence() {
      let timeSequence = `frame.time_relative >= ${this.startTimeRange.toFixed(6)} and frame.time_relative <= ${this.endTimeRange.toFixed(6)}`;
      this.loadPackets({ filter: timeSequence, name: 'setFilterRange' });
    },
    drawFrequencies() {
      const filtered = this.getTraffic.map(pck => [parseFloat(pck.endInterval * 1000), parseInt(pck.totalBytes)]);
      filtered.unshift([0, 0])
      filtered.push([parseFloat(filtered[filtered.length - 1][0] + 1000), 0])

      this.chartOptions.series.forEach((sr, ind) => {
        let notSpread = [...filtered];
        sr.boostThreshold = 200;
        sr.data = notSpread;
        ind === (sr.length - 1) && this.$refs.chart.chart.redraw();
      });
    }
  },
  mounted() {
    this.loadTraffic(this.$router.history.current.params.uuid);
    this.range = document.querySelector('.range-filter-controller');
    this.fullWidth = this.range.clientWidth - 12;
    if (this.traffic) {
      this.drawFrequencies();
    }
  }
}
</script>

<style scoped>
.range-filter .range-filter-controller {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 200px;
  border-radius: 6px;
  /* height: 33px; */

  position: relative;
}

.range-filter-controller .button-container {
  position: relative;
  width: 13px;
  height: 100%;
  /* height: 35px; */
}

.range-filter-controller .button-container span {
  /* height: 35px; */
  height: 100%;
  width: 1px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: #03C0FC;
}

.range-filter .range-mask {
  position: absolute;
  background: url('../../assets/range_mask.png');
  width: calc(100% - 13px);
  height: 100%;
  opacity: 0.5;
  left: 0;
  right: 0;
  margin: auto;
}

.range-filter .range-filter-controller button {
  width: 13px;
  height: 13px;
  border-radius: 4px;
  z-index: 1;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  background: #fff;
  border: 1px solid #03C0FC;
  cursor: default;
}

.filter-button {
  overflow: hidden;
}

.hc {
  position: absolute;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease-out;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
