<template>
  <div class="dialog-body geoip-worldmap">
    <div class="dialog-header">

      <div class="title-section">

        <div class="header-title">
          <span class="title-text">{{ title }}</span>
          <span class="file-name">{{ filename }}</span>
        </div>

      </div>

      <div class="close-section">
        <button class="close-dialog" v-on:click="closeDialog" :ref="'close'">
          <span></span>
        </button>
      </div>

    </div>

    <div class="content-wrapper">

      <div class="content">

        <div id="interactive-map">
        <span>Info</span>

          <div class="data-selection">
            Mapdata
            <div class="dropdown">
              <span class="icon"></span>
            </div>
          </div>

          <svg id="geo-data" :width="width * 1.4" :height="height * 1.4"></svg>
          <div class="gradient-button"></div>
        </div>
      </div>
    </div>

    <div class="dialog-buttons">

      <div class="default-buttons">

        <button class="new-window" v-on:click="openNewWindow">
          <span>Open In New Window</span>
        </button>
        <button class="done" v-on:click="closeDialog">
          <span>Done</span>
        </button>

      </div>
    </div>
  </div>
</template>

<script>

import * as d3 from "d3";
import * as topojson from "topojson-client";
import { mapState, mapActions } from 'vuex'; //mapGetters,

export default {
  name: 'GeoIPWorldMap',
  props: {
    closeDialog: Function,
    fileName: String
  },
  data() {
    return {
      title: 'GeoIP World Map for ',
      filename: this.fileName || 'voip-extension.pcap',
      width: 477,
      height: 287,
      dataSet: '../../../data/countries.topo.json'
    }
  },
  computed: {
    ...mapState({
      dialogContent: state => state.packets.dialogContent,
      uuid: state => state.packets.uuid
    }),
  },
  methods: {
    drawMap() {
      const projection = d3.geoMercator()
        .scale(90).translate([this.width / 1.4, this.height]);
      const path = d3.geoPath().projection(projection);

      const svg = d3
        .select("#geo-data");

      const g = svg.append("g");

      const dataLoader = d3.json(this.dataSet);
      dataLoader.then(countries => {
        // console.log(countries);
        g.append("g")
          .attr("id", "countries");

        d3.select("#countries")
          .selectAll("path")
          .data(topojson.feature(countries, countries.objects.countries).features)
          .enter()
          .append("path")
          .attr("id", (d) => d.id)
          .attr("name", (d) => d.properties.name.toLowerCase())
          .attr("fill", '#F7F7F7')
          .attr("stroke", "rgb(204, 204, 204)")
          // .attr("d", d => { console.log(path(d)); return path(d) })
          .attr("d", d => path(d))
          .on("mouseover", e => {
            e.target.setAttribute("stroke", "#000")
          })
          .on("mouseout", e => {
            e.target.setAttribute("stroke", "rgb(204, 204, 204)")
          })
          .on("click", e => {
            this.mapSelectionMethods(e);
          });
      }).catch(e => console.log(e));
    },
    mapSelectionMethods(e) {
      console.log(e);
    },
    ...mapActions({
      openDialog: 'packets/loadDialogData',
      toggleWindow: 'views/toggleWindow'
    }),
    openNewWindow() {
      let routeData = this.uuid && this.$router.resolve({ path: `window/${this.uuid}/${this.$options.name}` }); //query: {searchField: this.searchField}
      this.uuid && window.open(routeData.href, '_blank');
      this.toggleWindow({ value: this.$options.name });
      setTimeout(() => this.$refs.close.click(), 3000);
    },
  },
  mounted() {
    if (this.$router.history.current.name === 'window') {
      this.toggleWindow({ value: this.$options.name });
    }
    this.drawMap();
  },
}
</script>

<style scoped>
.geoip-worldmap {
  max-width: 90vw;
  max-height: 590px;
  width: 100%;
  /* height: 100%; */
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  margin-top: 5px;
}

.content .gradient-button {
  background: linear-gradient(90deg, #E6EBF5 0%, #003399 100%);
  width: 150px;
  height: 9px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
}

.content-wrapper .data-selection {
  position: absolute;
  display: flex;
  top: 0;
  width: max-content;
  align-items: center;
  z-index: 1;
}

.content-wrapper .data-selection .dropdown {
  border: 0.5px solid #000;
  border-radius: 6px;
  background: none;
  height: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 111px;
  margin-left: 10px;
}

.content-wrapper .data-selection .dropdown .icon {
  display: flex;
  text-indent: -9999px;
  width: 8px;
  height: 8px;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url('../../../assets/chevron_down.svg');
  mask-image: url('../../../assets/chevron_down.svg');
  -webkit-mask-size: 8px 8px;
  mask-size: 8px 8px;
  background-color: #000;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.content-wrapper .content {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  position: relative;
  margin-top: 20px;
}

.content-wrapper .content #interactive-map #geo-data {
  margin: auto;
}

.content-wrapper .content #interactive-map {
  overflow: auto;
  width: 100%;
  min-width: 667px;
  position: relative;
  display: flex;
}

.content-wrapper .additonal-description {
  font-size: 11px;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .content-wrapper .content {
    justify-content: initial;
    overflow: scroll;
    width: 100%;
  }
}

</style>
