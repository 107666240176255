<template>
  <div class="container narrow">
    <div class="homepage-logo">
      <img class="header-logo" src="../../assets/sipshark_logo.svg" alt="" />
      <h2 class="main-title">The easiest way to share SIP PCAP</h2>
      <FileUpload />
      <div class="about-section">
        <h2>About SIPshark.org</h2>
        <p>SIPshark.org is the fastest way to share a SIP pcap file without needing to download or install anything.
          It’s designed with your workflow in mind by using only a minimum number of keypresses to share your SIP pcap
          files.</p>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from '../shared/FileUpload.vue';
import { mapActions } from 'vuex';

export default {
  name: 'Home',
  components: {
    FileUpload
  },
  methods: {
    ...mapActions({
      uploadFile: 'packets/uploadAndGetUUID'
    })
  }
};
</script>

<style>

.wrapper-container {
  height: 100%;
}

.about-section h2{
  margin-top: 100px;
  font-size: 28px;
  font-weight: 300;
  color: #5C696A;
  margin-bottom: 52px;
}
.about-section p{
  max-width: 670px;
  margin: 0 auto;
  font-weight: 400;
  font-size: 16px;
  color: #5C696A;
  text-align: center;
  margin-bottom: 50px;
}
.homepage-logo {
  text-align: center;
  margin-top: 100px;
}
.homepage-logo .header-logo {
  height: 45px;
}
.homepage-logo .main-title {
  font-weight: 300;
  font-size: 32px;
  color: #5c696a;
  margin: 0px;
}
@media (max-width: 850px){
  .homepage-logo{
    margin-top: 80px;
  }
}
@media (max-width: 450px){
  .about-section h2{
    margin-top: 70px;
    margin-bottom: 30px;
  }
  .about-section p{
    margin-bottom: 153px;
  }
  .homepage-logo{
    margin-top: 60px;
  }
}
.container.narrow {
  max-width: 970px;
  margin: 0 auto;
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.mr-10 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.app-container {
  height: 100%;
  background: #F7F8F9;
  /* position: absolute; */
}

.main-header {
  background: #F7F8F9;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-header .main-logo {
  background: url('../../assets/logo/3-01.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 120px;
  height: 60px;
  margin-left: 20px;
}

.main-header .upload-button-main {
  cursor: pointer;
  border: 1px solid #03C0FC;
  color: #03C0FC;
  border-radius: 6px;
  background: #fff;
  padding: .8em 2.1em;
  margin-right: 20px;
}

.main-header #file-main {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

/* body::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background: #b5b7b7;
}

::-webkit-scrollbar-thumb {
  background: #606060;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

button:focus {
  outline: none;
}
</style>
