<template>
  <div class="container">
    <div class="terms-content">
      <h2>sipshark.org Terms of Service</h2>
      <p><i>Last Updated July 01, 2022</i></p>
      <p>
Thanks for using the sipshark.org services (including its website, and mobile and web-based applications, and any other tools, products, or services provided by Denovolab Limited that link to or reference these Terms) (collectively, the “Services”). The Services are provided by Denovolab Limited (“Denovolab Limited”, “we,” “our,” or “us”), located at 3524 Silverside Road Suite 35B, Wilmington, Delaware, 19810, U.S.A.
</p>

<p>
  By using our Services, you are agreeing to these Terms of Service (“Terms”). Please read them carefully. Our Services are very diverse, so sometimes additional terms or product requirements (including age requirements) may apply. If additional terms or conditions are available with or applicable to the relevant Services, then those additional terms become part of your agreement with us if you use those Services. By accessing or using the Services, you intend and agree to be legally bound by these Terms. You may wish to print or save a local copy of the Terms for your records.
</p>
<p>
  YOU ACKNOWLEDGE AND AGREE THAT THESE TERMS OF SERVICE LIMIT OUR LIABILITY AND THAT YOU ARE RELEASING US FROM VARIOUS CLAIMS IN SECTION 9 BELOW. THESE TERMS ALSO CONTAIN A BINDING ARBITRATION PROVISION IN SECTION 14 THAT AFFECT YOUR RIGHTS UNDER THESE TERMS WITH RESPECT TO THE SERVICES.
</p>
<h2>
  Using our Services
</h2>
<p>
  You must follow any policies made available to you within the Services, including our acceptable use policy.
</p>
<p>
  Don’t misuse our Services. For example, don’t interfere with our Services, try to access them using a method other than the interface and the instructions that we provide, or extensively or automatically copy any content from the Services (in other words, no scraping). You may use our Services only as permitted by law, including applicable export and re-export control laws and regulations. We may suspend or stop providing our Services to you if you do not comply with our terms or policies, if we are investigating suspected misconduct, or for any other reason.
</p>
<p>
  Using our Services does not give you ownership of any intellectual property rights in our Services or the content you access through them (“Content”). You may not use content from our Services, except as permitted by its owner or as otherwise permitted by law. These Terms do not grant you the right to use any branding or logos used in our Services, including the Denovolab Limited and sipshark.org names and logos. Don’t remove, obscure, or alter any legal notices displayed in or along with our Services.
</p>
<p>

  Our Services may display some Content that is not our own, for example, Content belonging to third parties (collectively, “Third Party Content”). We are not responsible for, and you waive all of our liability with respect to, Third Party Content. Third Party Content is the sole responsibility of the individual or entity that makes it available to you via the Services. We may review Third Party Content to determine whether it is illegal or violates our policies, and we may remove or refuse to display Third Party Content that we believe violates our policies or the law. But we do not generally review content beforehand, and we are not obligated to do so.
</p>
<p>

  In connection with your use of the Services, we may send you service announcements, administrative messages, and other information. You may opt out of some of those communications, for example, by clicking on the “unsubscribe” link in marketing e-mails.Please be aware that there may be a brief period before we are able to process your opt-out.
</p>
<p>
  Some of our Services are available on mobile devices, which may cause you to incur data charges with your wireless provider. Please be aware that we have no control over these charges, and if you do not wish to be charged, you should stop using the mobile features.
</p>
<h2>
  Your sipshark.org Account
</h2>
<p>
  You may need an account in order to use certain premium aspects of the Services. If you create your own account, you agree that all registration information you give us will be accurate and current. If your account has been assigned to you by an administrator, such as your employer or educational institution, different or additional terms may apply and your administrator may be able to access or disable your account. You will timely notify us of any changes to any of the foregoing information. You are responsible for controlling access to any PCs, mobile devices, or other end points that you allow to store your Services password, or on which you enable a "Remember Me" or similar functionality ("Activated Device"). Accordingly, you agree that you will be solely responsible for all activities that occur under your Services accounts, including the activities of any individual with whom you share your Services account or an Activated Device.
</p>
<p>
  To protect your account, keep your password confidential. You are responsible for the activity that happens on or through your account. If you learn of any unauthorized use of your password, please contact us at support@sipshark.org.
</p>
<h5>
  Payment
</h5>
<p>
  You agree to pay all amounts owed to us for the Services under any terms, policies or other written or electronic agreement we may have in place. We, or our third-party service providers (such as Stripe Inc.), may require you to maintain valid credit card or other payment account information with us in order to receive the Services, and if so, you hereby authorize us and/or them (as applicable) to charge your credit card or other payment account for the Services. Any failure to maintain valid, up-to-date payment information with us or to keep your payments current will constitute a material breach of these terms, for which we may suspend or terminate your access to the Services immediately without notice. Interest will be charged on all unpaid delinquent amounts at the rate of 1.5% per month or the highest rate permitted by applicable law, whichever is lesser. You agree to reimburse us for all collection agency fees, attorneys’ fees, and other costs we may incur to collect delinquent amounts you owe to us.
</p>
<h5>
  Privacy and Feedback
</h5>
<p>
  Our privacy policy explains how we treat your personal information and protect your privacy when you use our Services. By using our Services, you agree that we can collect, use, and share data from you as described in our privacy policy. We are not responsible for any information or Content collected by users that you submit or make available via your use of the Services. You assume all privacy, security, and other risks associated with providing any information, including personally identifiable information, to other users of the Service.
</p>
<p>
  Denovolab Limited has not received a National Security Letter, FISA order, or any other classified request for user information.
</p>
<p>
  If you submit feedback or suggestions about our Services, you agree that we may use your feedback or suggestions without obligation to you.
</p>
<h5>
  Files You Submit or Share
</h5>
<p>
  You may submit, upload, and share files, videos, pictures, text, and other content to or through the Services (“Your File(s)”), and in doing so you must follow these Terms and the rules and policies referenced in these Terms. You retain ownership of any intellectual property rights that you hold in Your Files. In short, what belongs to you stays yours.
</p>
<p>
  When you upload, submit, or otherwise share Your Files to or through our Services, you give us (and those we work with) a royalty-free, worldwide license to use, host, store, reproduce, communicate, and distribute Your Files. The rights you grant in this license are for the limited purpose of operating, promoting, and improving our Services, and to develop new ones. Make sure you have the necessary rights to grant us this license for any content and files that you submit to our Services.
</p>
<p>
  The Services will make Your File(s) available for download until: (a) Your File(s) is first downloaded, or (b) the applicable expiration date for the download link has passed (collectively, “File Expiration”), whichever is sooner. Following File Expiration, we use reasonable efforts to completely delete Your File(s) from the Services [in real time]. We assign an expiration date of two weeks to Your File(s) by default, but you may adjust this date from within the Services. For added security, we use industry-standard techniques to protect Your File(s) while they are stored within the Services. You may request that we delete any of Your Files within the Services by sending us an email at support@sipshark.org. To the extent within our control we’ll delete Your Files (if permitted by applicable law).
</p>
<p>
  In addition, you acknowledge and agree that the Services are not a data backup service or designed to be used as one. You should not rely on the Services to backup, save, or store any of Your Files, and we expressly disclaim all liability and responsibility for any loss of data, information, or content contained in Your Files.
</p>
<p>

  You further agree that you will not use the Services to:
Violate law or a third-party’s rights (including a third party’s intellectual property and privacy rights);
Submit excessive or unsolicited commercial messages or spam any users;
Submit malicious content or viruses;
Solicit other people’s login information, credit card numbers, or other sensitive information;
Harass or bully other users; or
Post content that is hate speech, threatening or pornographic, that incites violence or that contains nudity or graphic or gratuitous violence.
</p>
<h5>
  INTELLECTUAL PROPERTY PROTECTION
</h5>
<p>
  As we ask others to respect our intellectual property rights, we respect the intellectual property rights of others, and require our users and customers to do so. If you are a copyright owner or its agent and believe that any content residing on or accessible through the Services infringes upon your copyrights, you may submit a notification under the Digital Millennium Copyright Act (“DMCA”) by providing our Copyright Agent (the “Designated Agent”) with the following information in writing (see 17 U.S.C § 512(c)(3) for further detail):
Identification of the work or material being infringed.
Identification of the material that is claimed to be infringing, including its location, with sufficient detail so that we are capable of finding it and verifying its existence.
Contact information for the notifying party (the "Notifying Party"), including name, address, telephone number, and email address.
A statement that the Notifying Party has a good faith belief that the material is not authorized by the copyright owner, its agent or law.
A statement made under penalty of perjury that the information provided in the notice is accurate and that the Notifying Party is authorized to make the complaint on behalf of the copyright owner.
A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright that has been allegedly infringed.
</p>
<p>
  Please also note that the information provided in a notice of copyright infringement may be forwarded to the user who posted the allegedly infringing content. After removing material in response to a valid DMCA notice, we will notify the user responsible for the allegedly infringing material that we have removed or disabled access to the material. We will terminate, under appropriate circumstances, users who are repeat copyright infringers, and we reserve the right, in our sole discretion, to terminate any user for actual or apparent copyright infringement.
</p>
<p>
  If you believe you are the wrongful subject of a DMCA notification, you may file a counter-notification with us by providing the following information to the Designated Agent at the address below:

  The specific URLs of material that we have removed or to which we have disabled access.
Your name, address, telephone number, and email address.
A statement that you consent to the jurisdiction of U.S. District Court for the Eastern District of New Jersey, and that you will accept service of process from the person who provided the original DMCA notification or an agent of such person.
The following statement: "I swear, under penalty of perjury, that I have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled."
Your signature.
Upon receipt of a valid counter-notification, we will forward it to Notifying Party who submitted the original DMCA notification. The original Notifying Party (or the copyright holder he or she represents) will then have ten (10) days to notify us that he or she has filed legal action relating to the allegedly infringing material. If we do not receive any such notification within ten (10) days, we may restore the material to the Services.
</p>
<p>

  The contact information for our Designated Agent is:
Denovolab Limited
Attention: Copyright Agent
P.O. Box 15004
Philadelphia, PA 19130
Email: support@sipshark.org
</p>
 <p>
   If you believe that any of your intellectual property rights other than copyrights have been infringed, please e-mail us at support@sipshark.org. We reserve the right, in our sole and absolute discretion, to suspend or terminate any user who infringes the intellectual property rights of Denovolab Limited, or others, and/or to remove, delete, edit or disable access to such person’s content. You agree that we have no liability for any action taken under this section.
 </p>
 <h5>
   About Software in our Services
 </h5>
 <p>

   The Services may enable you to access software running on our (or our vendors’) servers (collectively, “Software”). You agree that we retain the ownership of all rights, title, and interest in and to the Software. Certain Software may update automatically on your device once a new version or feature is available, and you consent to such automatic updating.
 </p>
 <p>

   Denovolab Limited gives you a personal, worldwide, royalty-free, non-assignable, and non-exclusive license to use the Software to access the Services. This license is for the sole purpose of enabling you to use and enjoy the benefit of the Services as provided by us, in the manner permitted by these Terms. You may not copy, modify, distribute, sell, or lease any part of our Services or Software, nor may you reverse engineer or attempt to extract the source code of the Services or Software, unless laws prohibit those restrictions or you have our written permission.
 </p>
 <p>

   There may be software programs contained within certain Software that have been licensed to us by third parties. The term "Software" as used herein shall refer to this third-party software except where the term “Software” is used in the context of our ownership. The same terms and conditions, including all limitations and restrictions, set forth in these Terms apply to each third-party software program contained in the Software. You acknowledge and agree that any third-party components are owned by their applicable licensors. We do not make any representations or warranties about the operation or availability of such third-party software. Neither we, nor our licensors, shall be liable for any unavailability or removal of such third-party software. We are not responsible for any communications to or from such licensors, or for the collection or use of information by such licensors. You consent to the communications enabled and/or performed by such third-party software, including automatic updating of the third-party software without further notice. You agree that such third-party software licensors are intended third-party beneficiaries under these Terms.
 </p>
 <h5>
   Modifying and Terminating our Services
 </h5>
 <p>
   We are constantly changing and improving our Services. We may add or remove functionalities or features, and we may suspend or stop a Service altogether, at any time, without any notice or liability.
You can stop using our Services at any time, although we’ll be sorry to see you go. We may also stop providing Services to you, or add or create new limits to our Services, at any time.
Sections 8 – 14 will survive termination or expiration of these Terms indefinitely.
 </p>
 <h5>
   Our Warranties and Disclaimers
 </h5>
 <p>

   OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS, NEITHER Denovolab Limited NOR ITS LICENSORS, SUPPLIERS, ADVERTISERS, OR DISTRIBUTORS MAKE ANY SPECIFIC PROMISES ABOUT THE SERVICES. FOR EXAMPLE, WE DON’T MAKE ANY COMMITMENTS ABOUT THE CONTENT WITHIN THE SERVICES, THE SPECIFIC FUNCTIONS OF THE SERVICES, OR THEIR RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS. WE ALSO DO NOT MAKE ANY WARRANTIES OR COMMITMENT RELATING TO NON-INFRINGEMENT, FREEDOM FROM VIRUSES OR OTHER HARMFUL CODE, OR ERROR-FREE OR UNINTERRUPTED OPERATIONS. WE PROVIDE THE SERVICES “AS-IS.”
SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. TO THE EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES.
YOU AND YOUR HEIRS, SUCCESSORS, AND ASSIGNS HEREBY FOREVER IRREVOCABLY RELEASE, DISCHARGE, AND HOLD HARMLESS US, OUR AFFILIATES, AND OUR AND THEIR SUCCESSORS AND ASSIGNS, AND ITS AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS (COLLECTIVELY, “RELEASED PARTIES”) FROM, AND AGREE NOT TO SUE ANY RELEASED PARTY FOR, ANY LIABILITIES, CLAIMS, OBLIGATIONS, SUITS, ACTIONS, DEMANDS, EXPENSES, AND DAMAGES WHATSOEVER (COLLECTIVELY, “LIABILITIES”) THAT YOU MAY HAVE AGAINST ANY RELEASED PARTY WHETHER EXISTING NOW OR IN THE FUTURE, WHETHER KNOWN OR UNKNOWN, ARISING OUT OF OR IN CONNECTION WITH YOUR OR A THIRD PARTY’S CONDUCT RELATED TO USE OF THE SERVICES. YOU UNDERSTAND AND ACKNOWLEDGE THAT THE FOREGOING SENTENCE RELEASES AND DISCHARGES ALL LIABILITIES, WHETHER OR NOT THEY ARE CURRENTLY KNOWN TO YOU, AND YOU WAIVE YOUR RIGHTS UNDER CALIFORNIA CIVIL CODE SECTION 1542.
 </p>
 <h5>
   Liability for our Services
 </h5>
 <p>

   WHEN PERMITTED BY LAW, Denovolab Limited (AND ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS) AND OUR LICENSORS, SUPPLIERS, ADVERTISERS, AND DISTRIBUTORS, WILL NOT BE RESPONSIBLE FOR LOST PROFITS, REVENUES, OR DATA, FINANCIAL LOSSES OR INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES.
TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF Denovolab Limited (AND ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS) AND OUR LICENSORS, SUPPLIERS, ADVERTISERS, AND DISTRIBUTORS, FOR ANY AND ALL CLAIMS UNDER THESE TERMS OR RELATING TO YOUR USE OF THE SERVICES, INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT YOU PAID US TO USE THE SERVICES (OR, IF WE CHOOSE, TO SUPPLY YOU THE SERVICES AGAIN).
IN ALL CASES RELATING TO PROVIDING YOU THE SERVICES, Denovolab Limited (AND ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS) AND ITS LICENSORS, SUPPLIERS, ADVERTISERS, AND DISTRIBUTORS, WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE OR THAT IS DUE TO EVENTS OUTSIDE OF OUR REASONABLE CONTROL, SUCH AS WARS, CRIMINAL ACTIVITIES, STORMS, NATURAL DISASTERS, ACTS OF GOVERNMENT, SUPPLY INTERRUPTIONS, OR TELECOMMUNICATION OR INTERNET FAILURES.
 </p>
 <h5>
   Business/Employer Uses of our Services
 </h5>
 <p>

   If you are using our Services on behalf of a business or employer, you are accepting these Terms on their behalf, and that business or employer agrees to be bound by these Terms.
 </p>
<h5>
  Indemnification
</h5>
<p>

  You hereby agree to indemnify, defend, and hold harmless Denovolab Limited its affiliated companies, and its and their predecessors, successors, and assigns, and its and their respective directors, officers, employees, agents, representatives, partners, and contractors from and against all claims, losses, expenses, damages and costs (including, but not limited to, reasonable attorneys' fees), resulting from or arising out of your actual or alleged breach of these Terms, any content you provide through the Services, or your use or misuse of the Services. However, you will not be responsible for claims, damages, and costs which are found by a court of competent jurisdiction to have arisen solely from our wrongful acts or omissions.
</p>
<h5>
  About these Terms
</h5>
<p>

  We may modify these Terms or any additional terms that apply to a Service for any reason, for example, to reflect changes to the law or changes to our Services. You should look at the Terms regularly and the “Last Updated” date at the beginning of these Terms. We’ll use reasonable efforts to give you notice of these modifications, such as posting notice of modifications to these Terms on this web page, through the Services, or via email. By continuing to use the Services after we make these modifications, you agree that you will be subject to the modified Terms. If you do not agree to the modified terms for a Service, you should discontinue your use of that Service.
</p>
<p>

  If there is a conflict between these Terms and any additional terms for a Service, the additional terms will control for that conflict.
</p>
<p>

  These Terms control the relationship between Denovolab Limited and you. They do not create any third-party beneficiary rights (except in the limited case of Section 13). If you do not comply with these Terms, and we don’t take action right away, this doesn’t mean that we are giving up any rights that we may have (such as taking action in the future). If it turns out that a particular term is not enforceable, this will not affect any other terms.
</p>
<p>
  The laws of the United States and the Commonwealth of Pennsylvania, excluding New Jersey’s conflict of laws rules, will apply to any disputes arising out of or relating to these terms or the Services.
</p>
<p>

  You may not assign or delegate your rights or obligations relating to these terms or your account for the Services without our prior written consent. We may assign these terms or assign or delegate any of our rights or obligations at any time.
</p>
<p>

  For information,please send us an email at support@sipshark.org.
</p>
<h5>
  14. Binding Arbitration
</h5>
<p>

  Without limiting your waiver in Section 9, you agree to the following:
<br/>a. Purpose. Any and all Disputes (as defined below) involving you and Denovolab Limited will be resolved through individual arbitration. In arbitration, there is no judge or jury and there is less discovery and appellate review than in court. This Section 14 (the "Arbitration Provision") shall be broadly interpreted. Notwithstanding anything to the contrary in these Terms, this Section 14 does not apply to an action by either party to enjoin the infringement or misuse of its intellectual property rights, including copyright, trademark, patent or trade secret rights.
<br/>b. Definitions. The term "Dispute" means any claim or controversy related to the Services or the Software, including but not limited to any and all: (1) claims for relief and theories of liability, whether based in contract, tort, fraud, negligence, statute, regulation, ordinance, or otherwise; (2) claims that arose before these Terms or any prior agreement; (3) claims that arise after the expiration or termination of these Terms; and (4) claims that are currently the subject of purported class action litigation in which you are not a member of a certified class. As used in this Arbitration Provision, "Denovolab Limited" means Denovolab Limited and any of its predecessors, successors, assigns, parents, subsidiaries and affiliated companies and each of their respective officers, directors, employees and agents, and “you” means you and any users or beneficiaries of your access to the Services or the Software.
<br/>c. Initiation of Arbitration Proceeding/Selection of Arbitrator. The party initiating the arbitration proceeding may open a case with JAMS, formerly Judicial Arbitration and Mediation Services, Inc., (“JAMS”) by visiting its website (www.jamsadr.com) or calling its toll-free number (1-800-352-5267). You may deliver any required or desired notice to Denovolab Limited by mail to P.O. Box 15004, Philadelphia, PA 19130 .
<br/>d. Right to Sue in Small Claims Court. Notwithstanding anything in this Arbitration Provision to the contrary, either you or Denovolab Limited may bring an individual action in a small claims court in the area where you access the Services if the claim is not aggregated with the claim of any other person and if the amount in controversy is properly within the jurisdiction of the small claims court.
<br/>e. Arbitration Procedures. This Arbitration Provision shall be governed by the Federal Arbitration Act. Arbitrations shall be administered by JAMS pursuant to its Streamlined Arbitration Rules and Procedures (the “JAMS Rules”) as modified by the version of this Arbitration Provision that is in effect when you notify Denovolab Limited about your Dispute. You can obtain the JAMS Rules from the JAMS by visiting its website (www.jamsadr.com) or calling its toll-free number (1-800-352-5267). If there is a conflict between this Arbitration Provision and the rest of these Terms, this Arbitration Provision shall govern. If there is a conflict between this Arbitration Provision and the JAMS rules, this Arbitration Provision shall govern. If JAMS will not administer a proceeding under this Arbitration Provision as written, the parties shall agree on a substitute arbitration organization. If the parties cannot agree, the parties shall mutually petition a court of appropriate jurisdiction to appoint an arbitration organization that will administer a proceeding under this Arbitration Provision as written applying the JAMS Rules. A single arbitrator will resolve the Dispute. Unless you and Denovolab Limited agree otherwise, any arbitration hearing will take place in New Jersey. The arbitrator will honor claims of privilege recognized by law and will take reasonable steps to protect customer account information and other confidential or proprietary information. The arbitrator shall issue a reasoned, written decision that explains the arbitrator’s essential findings and conclusions. The arbitrator’s award may be entered in any court having jurisdiction over the parties only if necessary for purposes of enforcing the arbitrator’s award. An arbitrator’s award that has been fully satisfied shall not be entered in any court.
<br/>f. Waiver of Class Actions and Collective Relief. THERE SHALL BE NO RIGHT OR AUTHORITY FOR ANY CLAIMS TO BE ARBITRATED OR LITIGATED ON A CLASS ACTION, JOINT OR CONSOLIDATED BASIS OR ON BASES INVOLVING CLAIMS BROUGHT IN A PURPORTED REPRESENTATIVE CAPACITY ON BEHALF OF THE GENERAL PUBLIC (SUCH AS A PRIVATE ATTORNEY GENERAL), OTHER SUBSCRIBERS, OR OTHER PERSONS. THE ARBITRATOR MAY AWARD RELIEF ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF WARRANTED BY THAT INDIVIDUAL PARTY’S CLAIM. THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING.
<br/>g. Arbitration Fees and Costs.  You are responsible for arbitration costs and you may hire an attorney to represent you in arbitration. You are responsible for your attorneys’ fees and additional costs and may only recover your attorneys’ fees and costs in the arbitration to the extent that you could in court if the arbitration is decided in your favor. Notwithstanding anything in this Arbitration Provision to the contrary, Denovolab Limited will pay all fees and costs that it is required by law to pay.
<br/>h. Severability and Waiver of Jury Trial. If any part of subsection (f) of this Arbitration Provision is found to be illegal or unenforceable, the entire Arbitration provision will be unenforceable and the Dispute will be decided by a court. WHETHER IN COURT OR IN ARBITRATION, YOU AND Denovolab Limited AGREE TO WAIVE THE RIGHT TO A TRIAL BY JURY TO THE FULLEST EXTENT ALLOWED BY LAW. If any other clause in this Arbitration Provision is found to be illegal or unenforceable, that clause will be severed from this Arbitration Provision and the remainder of this Arbitration Provision will be given full force and effect.
</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.terms-content{
  margin: 100px 0px;
  color: #5C696A;
}
.terms-content h5{
  font-size: 18px;
}
</style>