<template>
  <div class="window-container">
    <component
      v-bind:is="currentWindow.value"
      v-bind:mode="currentWindow.mode"
      v-bind:isWindow="true"
      v-bind:closeDialog="toggleWindow"
      v-bind:fileName="uploadedFile.fileName" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

// import DNSActivity from './content/DNSActivity';
import FollowUDPStream from './content/FollowUDPStream';
// import ThreatAssesment from './content/ThreatAssesment';
import VoIPCalls from './content/VoIPCalls';
// import WirelessNetworks from './content/WirelessNetworks';
// import ZeekLogs from './content/ZeekLogs';
import GeoIPWorldMap from './content/GeoIPWorldMap';
// import HTTPAnalysis from './content/HTTPAnalysis';
import NetworkEndpoints from './content/NetworkEndpoints';
import PacketLengths from './content/PacketLengths';
import ProtocolConversations from './content/ProtocolConversations';
import ProtocolHierarchyStats from './content/ProtocolHierarchyStats';
import ProtocolLadderView from './content/ProtocolLadderView';
import RTPStreams from './content/RTPStreams';
import FileDetails from './content/FileDetails';
import LeftPaneDialog from './content/LeftPaneDialog';
// import Profile from './content/Profile';
import GraphsStats from './content/GraphsStats';
import GraphsDialog from './content/GraphsDialog';
import SIPStats from './content/SIPStats';

export default {
  name: 'EntireWindow',
  components: {
    // DNSActivity,
    FollowUDPStream,
    // ThreatAssesment,
    VoIPCalls,
    // WirelessNetworks,
    // ZeekLogs,
    GeoIPWorldMap,
    // HTTPAnalysis,
    NetworkEndpoints,
    PacketLengths,
    ProtocolConversations,
    ProtocolHierarchyStats,
    ProtocolLadderView,
    RTPStreams,
    FileDetails,
    LeftPaneDialog,
    // Profile,
    GraphsStats,
    GraphsDialog,
    SIPStats
  },
  data() {
    return {
      
    }
  },
  computed: {
    ...mapState({
      currentWindow: state => state.views.currentWindow,
      // currentDialog: state => state.views.currentDialog
      // uploadedFile: state => state.packets.uploadedFile
    }),
    ...mapGetters('packets', {
      uploadedFile: 'uploadedFileFormat',
    }),
    windowRoute() {
      return this.$router.history.current.params.window
    }
  },
  watch: {
    windowRoute(newValue, oldValue) {
      console.log(newValue, oldValue, 'updated window')
    }
  },
  methods: {
    ...mapActions({
      toggleWindow: 'views/toggleWindow',
      openStaticDialog: 'views/toggleDialog',
      loadDialog: 'packets/loadDialogData',
      setUUID: 'packets/setUUID'
    })
  },
  mounted() {
    // const self = this;
    // document.addEventListener('click', function (ev) {
    //   if (ev.target.className === 'file-name') {
    //     self.$router.replace('/');
    //     self.$router.go();
    //     self.openStaticDialog({value: ''});
    //     self.toggleWindow({value: ''});
    //   }
    // });
    if (this.$router.history.current.name === 'window') {
      this.setUUID(window.location.pathname.split('/')[2])
      // this.loadDialog({value: this.windowRoute});
      this.toggleWindow({value: this.windowRoute });
      // this.openStaticDialog({value: this.currentWindow.value});
    }
  }
}
</script>

<style scoped>
/* .dialog-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.23);
  left: 0;
  top: 0;
  z-index: 15;
} */

/* .window-container {
    position: absolute;
    left: 0;
    top: 60px;
    z-index: 20;
    width: 100%;
    height: calc(100% - 60px);
} */
/* .dialog-window {
    z-index: 100;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 20px;
} */
.dialog-body.follow-stream,
.dialog-body.network-endpoints,
.dialog-body.protocol-conversations,
.dialog-body.protocol-hierarchy ,
.dialog-body.http-analysis ,
.dialog-body.dns-activity ,
.dialog-body.geoip-worldmap ,
.dialog-body.rtp-streams , 
.dialog-body.wireless-networks , 
.dialog-body.packet-lengths , 
.dialog-body.voip-calls , 
.dialog-body.zeek-logs , 
.dialog-body.threat-assesment,
.dialog-body.graph-data {
  max-width: none;
  max-height: none;
  /* top: 70px; */
  border-radius: 0;
  /* padding-top: 20px; */
  height: 100%;
  position: relative;
  margin-bottom: 62px;
  /* height: calc(100vh - 70px); */
}

.sip-stats {
  height: auto;
}

/deep/ .dialog-body .file-name {
  cursor: pointer;
}
/deep/ .dialog-body.follow-stream .default-buttons,
/deep/ .dialog-body.follow-stream .close-section,

/deep/ .dialog-body.network-endpoints .default-buttons,
/deep/ .dialog-body.network-endpoints .close-section,

/deep/ .dialog-body.protocol-conversations .default-buttons,
/deep/ .dialog-body.protocol-conversations .close-section,

/deep/ .dialog-body.protocol-hierarchy .default-buttons,
/deep/ .dialog-body.protocol-hierarchy .close-section,

/deep/ .dialog-body.http-analysis .default-buttons,
/deep/ .dialog-body.http-analysis .close-section,

/deep/ .dialog-body.dns-activity .default-buttons,
/deep/ .dialog-body.dns-activity .close-section,

/deep/ .dialog-body.geoip-worldmap .default-buttons,
/deep/ .dialog-body.geoip-worldmap .close-section,

/deep/ .dialog-body.rtp-streams .default-buttons,
/deep/ .dialog-body.rtp-streams .close-section,

/deep/ .dialog-body.wireless-networks .default-buttons,
/deep/ .dialog-body.wireless-networks .close-section,

/deep/ .dialog-body.packet-lengths .default-buttons,
/deep/ .dialog-body.packet-lengths .close-section,

/deep/ .dialog-body.voip-calls .default-buttons,
/deep/ .dialog-body.voip-calls .close-section,

/deep/ .dialog-body.zeek-logs .default-buttons,
/deep/ .dialog-body.zeek-logs .close-section,

/deep/ .dialog-body.threat-assesment .default-buttons,
/deep/ .dialog-body.threat-assesment .close-section,
/deep/ .dialog-body.graph-data .close-section,
/deep/ .dialog-body.graph-data .default-buttons,
/deep/ .dialog-body.sip-stats .close-section,
/deep/ .dialog-body.sip-stats .default-buttons {
    display: none;
}
.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 13px 18px 0;
}
.title-section {

}
.header-title {

}
.title-text {
  margin-right: 5px;
}
.file-name {
  color: #03C0FC;
}

.close-section {

}

.close-dialog {
  background: none;
  border: none;
  cursor: pointer;
}

.close-dialog span {
  display: flex;

  text-indent: -9999px;
  width: 27px;
  height: 27px;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url('../../assets/close_dialog_icon.svg');
  mask-image: url('../../assets/close_dialog_icon.svg');
  -webkit-mask-size: 27px 27px;
  mask-size: 27px 27px;
  background-color: #000;
}

.content-wrapper {
  margin-right: 18px;
  margin-left: 18px;
}

.dialog-buttons {
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;
  margin-bottom: 25px;
}
.default-buttons {
  display: flex;
  justify-content: space-between;
  width: 257px;
}
.default-buttons button {
  border: 0.5px solid #03C0FC;
  border-radius: 6px;
  background: none;
  color: #03C0FC;
  height: 35px;
}
.new-window {
  width: 154px;
}
.done {
  width: 85px;
}
@media screen and (max-width: 767px) {
  .dialog-container {
    background: #000;
  }
}
</style>
