<template>
    <div class="dialog-body protocol-hierarchy">

      <div class="dialog-header">

        <div class="title-section">

          <div class="header-title">
            <span class="title-text">{{title}}</span>
            <span class="file-name">{{filename}}</span>
          </div>

        </div>

        <div class="close-section">
          <button
            class="close-dialog"
            v-on:click="closeDialog"
            :ref="'close'"
          >
            <span></span>
          </button>
        </div>

      </div>

      <div class="content-wrapper">
          <div class="additonal-description">
              Click on the protocol name to apply a Display Filter for only that traffic.
          </div>
          <div class="content">
            <table>
                <thead  >
                    <tr   style="text-align: left;">
                        <th
                            v-for="(tableHeader, ind) in tableHeaders"
                            v-bind:key="tableHeader.value + ind"
                            v-bind="tableHeader"
                            v-bind:class="tableHeader.value"
                            v-on:click="sortOrder(tableHeader, ind)">
                            <div class="container">
                                {{tableHeader.label}}
                                <div
                                class="order-button"
                                v-bind:class="{
                                    asc: (tableHeader.value === currentHeader.value)
                                        && currentHeader.order === 'asc',
                                    desc: (tableHeader.value === currentHeader.value)
                                        && currentHeader.order === 'desc'}"
                                >
                                    <span class="order-icon order-asc"></span>
                                    <span class="order-icon order-desc"></span>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody tabindex="0" class="show-annotations">
                    <tr
                      v-for="(entry, ind) in entriesOrdered"
                      v-bind:key="'entry_' + ind"
                      v-bind="entry"
                      v-on:click="searchByQuery(entry.protocol)"
                    >
                        <td
                          v-bind:class="{
                            'is-child': entry.child,
                            'is-parent': entry.parent
                          }"
                          class="bottom-border first" >{{entry.protocol}}</td>
                        <td   class="bottom-border second" >{{entry.frames}}</td>
                        <td    class="bottom-border" >
                            <div class="percent-container">
                              <div class="percent-result"
                                :style="{width: `${entry.framesPercent}%`}"
                              ></div>
                              <span class="percent-text">{{entry.framesPercent}}</span>
                            </div>
                        </td>
                        <td   class="bottom-border fourth" >{{entry.bytes}}</td>
                        <td    class="bottom-border" >
                            <div class="percent-container">
                              <div class="percent-result"
                                :style="{width: `${entry.bytesPercent}%`}"
                              ></div>
                              <span class="percent-text">{{entry.bytesPercent}}</span>
                            </div>
                        </td>

                    </tr>
                </tbody>
            </table>
          </div>
      </div>

      <div class="dialog-buttons">

        <div class="default-buttons">

          <button
            class="new-window"
            v-on:click="openNewWindow"
          >
            <span>Open In New Window</span>
          </button>
          <button
            class="done"
            v-on:click="closeDialog"
          >
            <span>Done</span>
          </button>

        </div>
      </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'; //mapGetters,

export default {
  name: 'ProtocolHierarchyStats',
  props: {
    closeDialog: Function,
    fileName: String
  },
  data () {
    return {
        title: 'Protocol Hierarchy Stats for ',
        filename: this.fileName || 'voip-extension.pcap',
        tableHeaders: [
            {
                value: 'protocol',
                label: 'Protocol',
            },
            {
                value: 'frames',
                label: 'Frames',
            },
            {
                value: 'frames-percent',
                label: 'Frames %',
            },
            {
                value: 'bytes',
                label: 'Bytes'
            },
            {
                value: 'bytes-percent',
                label: 'Bytes %'
            },
        ],
        entries: [],
        entriesOrdered: [],
        currentHeader: {},
        isLadder: false
    }
  },
  computed: {
    ...mapState({
      dialogContent: state => state.packets.dialogContent,
      currentDialog: state => state.views.currentDialog,
      uuid: state => state.packets.uuid
    }),
    dialogContentProps() {
      let listedProps = this.dialogContent &&
          Object.entries(this.dialogContent);
      return listedProps[0] && listedProps[0][1];
    }
  },
  watch: {
      dialogContentProps(newValue, oldValue) {
          console.log(newValue, oldValue);
        this.dataIteration(newValue)
      },
      dialogContent(newValue) {
        this.isLadder = newValue.isLadder;
      }
  },
  methods: {
      dataIteration(newValue) {
        if (newValue) {

          let filtered = [];

          const iterFilter = (dataSet, isChild, maxFrames, maxBytes) => {

            dataSet.forEach(frame => {
                let ch = isChild,
                maxF = maxFrames,
                maxB = maxBytes;
                console.log(ch, frame);
                if (frame.children) {
                    let {
                        parent = true, child = ch, name
                    } = frame;
                    let splitted = name.split(" ");
                    filtered.push({
                        parent,
                        name,
                        child,
                        protocol: splitted[0],
                        [splitted[1].split(":")[0]]: splitted[1].split(":")[1],
                        [splitted[2].split(":")[0]]: splitted[2].split(":")[1],
                        framesPercent: isChild ?
                            Math.abs(
                            parseInt(splitted[1].split(":")[1]) / (maxF / 100)
                            ).toFixed(2) :
                            100,
                        bytesPercent: isChild ?
                            Math.abs(
                            parseInt(splitted[2].split(":")[1]) / (maxB / 100)
                            ).toFixed(2) :
                            100,
                        maxF: maxF,
                        maxB: maxB
                    });
                    iterFilter(frame.children,
                        true,
                        isChild ? maxF : splitted[1].split(":")[1],
                        isChild ? maxB : splitted[2].split(":")[1],
                    );
                } else {
                    let {name, child = ch} = frame;
                    let splitted = name.split(" ");
                    filtered.push({
                        name,
                        child,
                        protocol: splitted[0],
                        [splitted[1].split(":")[0]]: splitted[1].split(":")[1],
                        [splitted[2].split(":")[0]]: splitted[2].split(":")[1],
                        framesPercent: isChild ?
                            Math.abs(
                            parseInt(splitted[1].split(":")[1]) / (maxF / 100)
                            ).toFixed(2) :
                            100,
                        bytesPercent: isChild ?
                            Math.abs(
                            parseInt(splitted[2].split(":")[1]) / (maxB / 100)
                            ).toFixed(2) :
                            100,
                        maxF: maxF,
                        maxB: maxB
                    });
                }
                console.log(filtered);
                return [...filtered];
            })
          }

          iterFilter(newValue);
          let frameInit = {...filtered[0], protocol: 'frame'};
          this.entries = [frameInit, ...filtered];
          this.entriesOrdered = this.entries;

          console.log(this.dialogContent[this.currentDialog])
        }
      },
      sortOrder(hd) {

        let currentOrder = this.currentHeader.order ?
              (this.currentHeader.order === 'asc' ? 'desc' : '') : 'asc';

          this.entriesOrdered = currentOrder ?
            (
              (currentOrder === 'asc') ?
              [...this.entries].sort((en, ensec) => ensec[hd.value] - en[hd.value]) :
              [...this.entries].sort((en, ensec) => ensec[hd.value] - en[hd.value]).reverse()
            ) : [...this.entries];

        this.currentHeader = {
          ...hd,
          order: currentOrder
        };
      },
    ...mapActions({
      openDialog: 'packets/loadDialogData',
      toggleWindow: 'views/toggleWindow',
      toggleDiagram: 'views/toggleDiagram',
      loadPackets: 'packets/getPacketsList',
    }),
    openNewWindow() {
      let routeData = this.uuid && this.$router.resolve({path: `window/${this.uuid}/${this.$options.name}`}); //query: {searchField: this.searchField}
      this.uuid && window.open(routeData.href, '_blank');
      this.toggleWindow({value: this.$options.name});
      setTimeout(() => this.$refs.close.click(), 3000);
    },
    searchByQuery(protocol) {
      let uniqueProtocols = [...new Set(this.entries.map(en => en.protocol))];
      let searchProtocolQuery = '';

      uniqueProtocols.forEach((prt, indx) => {
        if (prt === protocol) {
          searchProtocolQuery = uniqueProtocols.slice(0, indx + 1).join(" and ")
        }
      });

      let ladderSearch = `${searchProtocolQuery}`;

      if (this.isLadder) {
        this.loadPackets({filter: ladderSearch, name: 'setFilterField'});
        this.closeDialog('ProtocolHierarchyStats');
        this.toggleDiagram({switcher: true, searchQuery: ladderSearch});
        let routeData = this.uuid && this.$router.resolve({path: `/analysis/${this.uuid}/ladder`, query: {searchField: ladderSearch}});
        this.uuid && window.open(routeData.href, '_blank');

      } else {
        this.loadPackets({filter: ladderSearch, name: 'setFilterField'});
        this.closeDialog('ProtocolHierarchyStats');
      }
    }
  },
  mounted() {
    if (this.dialogContentProps && this.dialogContentProps.length > 0) {
      this.dataIteration(this.dialogContentProps)
    }
    if (this.$router.history.current.name === 'window') {
      this.toggleWindow({value: this.$options.name});
      // (this.entries.length <= 0) && this.dataIteration(this.dialogContentProps);
    }
  }
}
</script>

<style scoped>
.protocol-hierarchy {
  max-width: 90vw;
  max-height: 550px;
  width: 100%;
  height: 100%;
}

.protocol-hierarchy .header-title {
    display: flex;
    align-items: center;
}

.protocol-hierarchy .title-section {
    display: flex;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 5px;
}
.content-wrapper .additonal-description {
    font-size: 11px;
    margin-bottom: 25px;
}
.content-wrapper .content {
    display: flex;
    justify-content: initial;
    height: 100%;
    align-items: initial;
    overflow-y: scroll;
    max-height: calc(590px - 160px);
}
.content table {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    width: 100%;
}
.content table th:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.content table th:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.content table th {
    background: #EDEFF5;
    text-align: center;
    height: 15px;
    top: 0;
    position: sticky;
    cursor: pointer;
    z-index: 1;
}
.content table tr {
  cursor: pointer;
}
.content table tr:hover {
  cursor: pointer;
  background: rgba(0,0,0,0.3);
}
.content table tr td {
    text-align: center;
    height: 19px;
}
.content table th,
.content table tr td {
    width: 20%;
}

.content table td.bottom-border {
    border-bottom: 1px solid #C4C4C4;
}
.content table th .order-button {
    display: flex;
    flex-direction: column;
    width: 10px;
    margin: 0;
    align-items: center;
}
.content table th .container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}
.content table th .container {
  width: 95%;
  justify-content: space-between;
  margin-left: 5px;
}
.content table th .order-button .order-icon {
  display: flex;
  text-indent: -9999px;
  width: 5px;
  height: 9px;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url('../../../assets/arrow_icon.svg');
  mask-image: url('../../../assets/arrow_icon.svg');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 5px 9px;
  mask-size: 5px 9px;
  background-color: #000;
}

.content table th .order-button.asc .order-icon.order-asc,
.content table th .order-button.desc .order-icon.order-desc {
    background-color: #03C0FC;
}

.content table th .order-button .order-icon.order-asc {
  transform: rotate(270deg);
}

.content table th .order-button .order-icon.order-desc {
  transform: rotate(90deg);
}
.dialog-buttons {
    justify-content: flex-end;
}
table {
    min-width: 675px
}
.percent-container {
    background: rgba(3,192,252,0.43);
    border-radius: 2px;
    /* width: 108px; */
    width: 95%;
    margin: auto;
    height: 15px;
    padding: 0;
    position: relative;
}
.percent-container .percent-result {
    background: #03C0FC;
    height: 100%;
    position: absolute;
    font-size: 9px;
    border-radius: 2px;
}
.percent-text {
    position: absolute;
    font-size: 11px;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.content table tr td.first {
    text-align: left;
    padding-left: 2%;
}

.content table tr td.second,
.content table tr td.fourth {
  text-align: left;
  padding-left: 5px;
}
table tr td.first.is-child {
    padding-left: 4%;
}
table tr td.first.is-child.is-parent {
    padding-left: 6%;
}
@media screen and (max-width: 767px) {
    .protocol-hierarchy {
        max-height: 383px;
    }
    .protocol-hierarchy .content-wrapper {
        margin-top: 10px;
        margin-bottom: 10px;
        max-height: calc(383px - 100px);
    }
    .protocol-hierarchy .title-section {
        flex-direction: column;
    }
    .protocol-hierarchy .header-title {
        align-items: initial;
        flex-direction: column;
    }
}
</style>
