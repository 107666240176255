<template>
  <div class="container">
    <h2 class="main-title contact-section">Connect with SIPshark</h2>
    <div class="wrapper">
      <form class="form" @submit.prevent="submitHandler">
        <div class="two-input-row">
          <span class="input-block">
            <label>First Name</label>
            <input v-validate="'required'" v-model="formData.firstName" name="firstName" type="text">
            <p class="error-message">{{ errors.first('firstName') }}</p>
          </span>
          <span class="input-block">
            <label>Last Name</label>
            <input v-validate="'required'" v-model="formData.lastName" name="lastName" type="text">
            <p class="error-message">{{ errors.first('lastName') }}</p>
          </span>
        </div>
        <div class="two-input-row">
          <span class="input-block">
            <label>Email Address</label>
            <input v-validate="'required|email'" v-model="formData.email" name="email" type="text">
            <p class="error-message">{{ errors.first('email') }}</p>
          </span>
          <span class="input-block">
            <label>Phone Number</label>
            <input v-validate="'required|numeric'" v-model="formData.phone" name="phone" type="tel">
            <p class="error-message">{{ errors.first('phone') }}</p>
          </span>
        </div>
        <span class="text-left">
          <label> Ask a question or describe your problem</label>
          <textarea v-validate="'required'" v-model="formData.message" name="question" id=""></textarea>
          <span class="error-message">{{ errors.first('question') }}</span>
        </span>
        <div>
          <button type="submit" class="submit-btn">Submit</button>
        </div>
      </form>
      <div class="contact-us">
        <div class="contact-title">Skype Us:</div>
        <div class="contact-description" style="margin-bottom: 0">dnl_support</div>
        <hr />
        <div class="contact-title">Email Us:</div>
        <div class="contact-description">support@sipshark.org</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    formData: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: ''
    }
  }),
  methods: {
    clearForm () {
      Object.keys(this.formData).forEach(key => this.formData[key] = '');
      this.$validator.reset();
    },
    submitHandler () {
      this.$validator.validateAll()
        .then(result => {
          if (result) {
            const sms = `Subject: Sipshark \nName: ${this.formData.firstName} ${this.formData.lastName} \nEmail: ${this.formData.email} \nPhone: ${this.formData.phone} \nMessage: ${this.formData.message}`
            const payload = {
              text: sms,
              username: 'Sipshark Subscriber',
              channel: '#website-contact-form'
            }
            axios.post('https://mattermost.denovolab.com/hooks/fx3oo6dqsfn3mef8fekd89fb9e', payload, {
              headers: { 'Content-Type': 'application/json' }
            })
              .then(() => {
                this.$toast("Your form has been submitted");
                this.clearForm();
              })
              .catch(() => {
                this.$toast.error("Something went wrong. Try again.");
                this.clearForm();
              })
          }
      });
    },
  },

};
</script>

<style scoped>

hr {
  background: #8CC541;
  height: 1px;
  width: 40px;
  border: none;
  margin: 30px 0;
}

.contact-title {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 20px;
  color: #5c696a;
}

.contact-description {
  font-size: 16px;
  color: #5c696a;
  margin-bottom: 15px;
}

.contact-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background: #f7fafa;
  width: 100%;
  height: fit-content;
  border-radius: 10px;
}

.text-left {
  text-align: left;
  width: 100%;
}

.text-left textarea {
  width: 100%;
}

.submit-btn {
  background: #8cc541;
  width: 100%;
  color: white;
  font-size: 16px;
  font-weight: 500;
  padding: 16px 0;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 30px;
}

.wrapper {
  margin-bottom: 100px;
}

.two-input-row {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-self: start;
  width: 100%;
}

.two-input-row {
  /* display: grid; */
  text-align: left;
  width: 100%;
}

.two-input-row span:first-child {
  margin-right: 30px;
}

input,
textarea {
  font-family: Roboto;
  outline-color: #5c696a;
  border-radius: 3px;
  border: 1px solid #e2e6e9;
  padding: 14px;
  color: #5c696a;
  font-size: 14px 20px;
  min-width: 270px;
}

textarea {
  display: block;
  width: -webkit-fill-available;
  min-height: 160px;
  resize: none;
  margin-top: 10px;
  min-width: 270px;
}

.contact-section {
  text-align: center;
}

.main-title {
  margin-top: 100px;
  margin-bottom: 70px;
}

.wrapper {
  display: flex;
}

@media(max-width: 1240px) {
  .main-title {
    margin-top: 70px;
  }

  .container {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .wrapper {
    margin-bottom: 70px;
    flex-direction: column;
  }

  .contact-us {
    margin-top: 70px;
    width: auto;
  }

  .wrapper .form {
    margin-right: 0;
  }
}

@media(max-width: 850px) {
  .container {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}

@media(max-width: 700px) {
  .main-title {
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .two-input-row {
    margin-bottom: 20px;
      
  }

  textarea {
    margin-bottom: 20px;
  }

  .contact-us {
    margin-top: 50px;
  }

  .wrapper {
    margin-bottom: 50px;
  }

  .form .two-input-row>span {
    width: 100%;
  }

    input {
      min-width: unset;
      width: 100%;
      max-width: 100%;
    }
}

@media (max-width: 450px) {
  .two-input-row {
    flex-direction: column;
  }
     .two-input-row span {
      margin-bottom: 20px;
     }
    .two-input-row span:first-child {
      margin-right: 0;
    }
}

.form {
  margin-right: 130px;
}

label {
  display: inline-block;
  font-size: 14px;
  color: #5c696a;
  margin-bottom: 10px;
}

.input-block {
  width: 100%;
  position: relative;
}

.error-message {
  position: absolute;
  /* top: 10px; */
  font-size: smaller;
  color: red
}
</style>
