import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

import LadderView from '@/components/dialogs/LadderView';
import VoipFlow from '@/components/dialogs/VoipFlow';
import ErrorPage from '@/components/ErrorPage'
import EntireWindow from '@/components/dialogs/EntireWindow';

import Home from '@/components/pages/Home.vue';
import Dashboard from '@/components/pages/Dashboard.vue';
import AboutUs from '@/components/pages/AboutUs.vue';
import Contact from '@/components/pages/Contact.vue';
import PrivacyPolicy from '@/components/pages/PrivacyPolicy.vue'
import TermsOfService from '@/components/pages/TermsOfService.vue'


const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home', 
      component: Home,
    },
    {
      path: '/dashboard/:uuid',
      name: 'main',
      component: Dashboard,
    },
    { 
      path: '/about-us', 
      name: 'aboutUs', 
      component: AboutUs 
    },
    { 
      path: '/contact', 
      name: 'contact', 
      component: Contact 
    },
    { 
      path: '/privacy-policy', 
      name: 'privacy_policy', 
      component: PrivacyPolicy 
    },
    { 
      path: '/terms-of-service', 
      name: 'terms_of_service', 
      component: TermsOfService 
    },
    { path: '/error', name: 'error', component: ErrorPage },
    { path: '/analysis/:uuid/ladder', name: 'ladder', component: LadderView },
    { path: '/analysis/:uuid/voip_flow', name: 'flow', component: VoipFlow },
    { path: '/window/:uuid/:window', name: 'window', component: EntireWindow },
  ],
});


export default router
