<template>
  <div class="filter-button search-filter">
    <form @submit.prevent="applySearch">
      <input placeholder="Start typing a display filter" type="text" v-model="searchField"/>
    </form>
    <button class="apply" @click="applySearch">Apply</button>
    <button class="clear" @click="clearSearch">Clear</button>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'SearchFilter',
  data() {
    return {
      searchField: ''
    }
  },
  methods: {
    applySearch() {
        console.log(this.searchField, ' request');
        this.loadPackets({filter: this.searchField, name: 'setFilterField'});
    },
    clearSearch() {
        this.searchField = '';
        // this.removeAllPacketData();
        this.loadPackets({filter: '', name: 'setFilterField'});
    },
    ...mapActions({
      loadPackets: 'packets/getPacketsList',
      removeAllPacketData: 'packets/removeUploadedPacket'
    }),
  },
  computed: {
    ...mapState({
      filterFiled: state => state.packets.filterFiled
    })
  },
  watch: {
    filterFiled(newValue) {
      if (newValue !== this.searchField) {
        this.searchField = newValue;
      }
    },
    searchField (newValue, oldValue) {
      if (oldValue.length > 0 && newValue.length === 0) {
        this.applySearch();
      }
    }
  }
}
</script>

<style scoped>
.search-filter input {
    /* height: 35px; */
    padding: 0;
    border: none;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    /* width: 352px; */
    width: 16vw;
    height: 100%;
    text-indent: 14px;
}
/* .search-filter .apply {
    width: 73px;
} */
.search-filter .clear {
    border-left: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    /* width: 67px; */
}

@media screen and (max-width: 767px) {
    .search-filter input {
        width: 100%;
        max-width: 352px;
    }
}
input {
  outline: none;
}
</style>
