<template>
  <div class="footer-wrapper">
    <div class="container">
      <div class="footer-content">
        <div class="left-part">© Copyright 2022 &nbsp;<a href="http://www.denovolab.com">Denovolab Limited</a></div>
        <div class="right-part">
          <ul>
            <li>
              <router-link :to="{ name: 'terms_of_service' }">
                <a href="#">Terms of Service</a>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'privacy_policy' }">
                <a href="#">Privacy Policy</a>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'contact' }">
                <a href="#">Contact</a>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
@media (max-width: 720px){
  .left-part{
    width: 120px;
  }
  .footer-content .right-part ul{
    display: block;
    margin: 0;
  }
  .footer-content .right-part ul li{
    text-align: left;
    margin-right: 0;
    margin-bottom: 15px;
  }
    .footer-content .right-part ul li:last-child{
    margin-bottom: 0;
  }
  .footer-content .right-part ul li a{
    display: inline-block;
  }
}
.footer-wrapper{
  padding: 50px 0px;
  background: #F6F8F9;
}
.footer-content{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-wrapper .left-part, a{
  font-size: 14px;
  font-weight: 300;
  color: #5C696A;
}
.right-part ul, .right-part ul a{
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  color: #5C696A;
}
.right-part ul li{
  margin-right: 50px;
}
.right-part ul li a{
  text-decoration: none;
}
.right-part ul li:last-child{
  margin-right: 0;
}
</style>