<template>
  <div class="panes">
    <div class="vertical-panel">
      <splitpanes horizontal="horizontal">
        <pane max-size="80" min-size="20">
          <div :style="{ maxHeight: '100%', overflow: 'auto' }">
            <TableStats />
          </div>
        </pane>
        <pane>
          <splitpanes vertical="vertical">
            <pane min-size="20">
              <div :style="{ maxHeight: '100%', overflow: 'auto' }">
                <LeftPane />
              </div>
            </pane>
            <pane min-size="30">
              <div :style="{ maxHeight: '100%', overflow: 'auto' }">
                <RightPane />
              </div>
            </pane>
          </splitpanes>
        </pane>
      </splitpanes>
    </div>
  </div>
</template>

<script>
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

import TableStats from '../TableStats.vue';
import LeftPane from './LeftPane.vue';
import RightPane from './RightPane.vue';

export default {
  name: 'Panes',
  components: {
    Splitpanes, Pane,
    TableStats,
    LeftPane,
    RightPane
  }
}
</script>

<style>
.panes {
  width: 100%;
  height: calc(100% - 180px);
  bottom: 0;
  position: absolute;
  background: #F7F8F9;
}

.vertical-panel {
  height: 100%;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
}

.splitpanes__splitter {
  background: #c8c8c8;
  width: 5px;
}

.splitpanes--horizontal>.splitpanes__splitter {
  background: #c8c8c8;
  height: 5px;
  width: 100%;
}

.no-scrollbar {
  overflow-x: auto;
}

.panes-layout {
  margin-left: 0 !important;
  margin-right: 0 !important;
  background: #fff;
}

</style>
