<template>
    <div class="toolset-header">
        <input
          style="margin-left: 25px;"
          type="file" id="file" ref="upload" v-on:change="takePcap"
          accept=".pcap,.pcapng"
        />
        <div
          class="file-name">
            <span>{{!uploadedFile ? fileName : uploadedFile.fileName}}</span>
        </div>
        <div
          class="least-data">
            <div class="file-size">{{ !uploadedFile ? numberOfPackets : uploadedFile.numberOfPackets }}</div>
            <div class="file-packets">{{ !uploadedFile ? fileSize : `${ Math.abs(parseInt(uploadedFile.fileSize) / 1024).toFixed(1)} kb` }}</div>
            <div
              class="file-info"
              v-on:click="getInfo"
            >more info</div>
        </div>

    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'ToolsetHeader',
  data() {
      return {
          fileName: '',
          numberOfPackets: '',
          fileSize: ''
      }
  },
  computed: {
      ...mapGetters('packets', {
        uploadedFile: 'uploadedFileFormat',
      }),
      ...mapState('packets', {
        receivedId: 'uuid',
      })
  },
  watch: {
    receivedId() {
      console.log('this.getFileInfo')
    },
  },
  methods: {
  ...mapActions({
      uploadFile: 'packets/uploadAndGetUUID',
      getFileInfo: 'packets/getFileInfo',
      openDetails: 'views/toggleDialog'
  }),
  getInfo() {
    (this.uploadedFile && this.uploadedFile.fileName) && this.openDetails({value: 'FileDetails'});
  },
  async takePcap(ev) {
    console.log(ev.target.files);
    await this.uploadFile(ev.target.files[0]);
    // this.getFileInfo();
  }
  },
  mounted() {
    this.getFileInfo();
    // this.uploadFile();
  }
}
</script>

<style scoped>
.toolset-header {
  display: flex;
  font-size: 16px;
  width: 40%;
  justify-content: space-evenly;
  flex-direction: row;
  margin: 22px auto 22px 0;
  font-family: 'Roboto', sans-serif;
  position: relative;
  /* margin-left: 10%;
  margin-left: 0;
  margin-right: auto;
  */
}
.toolset-header .file-name {
  display: flex;
  flex-basis: 30%;
  color: #000;
  justify-content: center;
}
.toolset-header #file {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.toolset-header .least-data {
    display: flex;
    flex-basis: 60%;
}
.toolset-header .file-size {
  color: rgba(0, 0, 0, 0.38);
  margin-right: 5px;
}
.toolset-header .file-packets {
  color: rgba(0, 0, 0, 0.38);
  margin-right: 5px;
}
.toolset-header .file-info {
  color: #03C0FC;
  cursor: pointer;
}
.toolset-header .file-size::after,
.toolset-header .file-packets::after {
    content: " · "
}
.toolset-header .file-name span {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    white-space: nowrap;
    display: block;
}
@media screen and (max-width: 767px) {
    .toolset-header {
        flex-wrap: wrap;
        padding-left: 5%;
        width: 90%;
        justify-content: flex-start;
    }
    .toolset-header .file-name {
        flex-basis: 90%;
        margin-bottom: 10px;
        justify-content: flex-start;
    }
    .toolset-header .file-name span {
        max-width: 330px;
    }
    .toolset-header .least-data {
        flex-basis: 90%;
    }
}
</style>
