<template>
    <div class="dialog-body packet-lengths" :class="{window: isWindow}">
        <div class="dialog-header">

        <div class="title-section">

          <div class="header-title">
            <span class="title-text">{{title}}</span>
            <span class="file-name">{{filename}}</span>
          </div>

        </div>

        <div class="close-section">
          <button
            class="close-dialog"
            @click="closeDialog"
            :ref="'close'"
          >
            <span></span>
          </button>
        </div>

      </div>

      <div class="content-wrapper">
          <div class="additonal-description">
            Click on a bar to filter the capture file to only those packets.
          </div>

          <div class="content">
            <div id="packet-lengths-graph">

              <svg class="barchart" 
                preserveAspectRatio="xMinYMin meet" 
                :viewBox="`0 0 ${width * 1.6} ${height * 1.6}`">
                <g transform="translate(150, 70)">
                  <text x="-190" transform="rotate(270)" y="-50" fill="#000">Number of packets</text>
                  <text x="160" y="-40" fill="#000">Packet Lengths</text>
                  <g
                    v-if="(maxCount > 10) || ((maxCount % 3) === 0)" 
                    class="x-axis" fill="none" :transform="`translate(0, ${height / 3})`"
                  >
                    <text x="-30" y="5" fill="#000">{{Math.ceil(maxCount / 3) * 2}}</text>
                    <path stroke="rgba(0,0,0,0.19)" :d="`M0.5,0V0.5H${width}.5V0`"></path>
                  </g>
                  <g
                    v-if="(maxCount > 10) || ((maxCount % 3) === 0)"
                    class="x-axis" fill="none" :transform="`translate(0, ${(height / 3) * 2})`"
                  >
                    <text x="-30" y="5" fill="#000">{{Math.ceil(maxCount / 3)}}</text>
                    <path stroke="rgba(0,0,0,0.19)" :d="`M0.5,0V0.5H${width}.5V0`"></path>
                  </g>

                  <g
                    v-if="(maxCount <= 10) && ((maxCount % 2) === 0) && ((maxCount % 3) !== 0)"
                    class="x-axis" fill="none" :transform="`translate(0, ${(height / 2)})`"
                  >
                    <text x="-30" y="5" fill="#000">{{Math.ceil(maxCount / 2)}}</text>
                    <path stroke="rgba(0,0,0,0.19)" :d="`M0.5,0V0.5H${width}.5V0`"></path>
                  </g>

                  <g
                    v-if="(maxCount === 7)"
                    class="x-axis" fill="none" :transform="`translate(0, ${(height / 7) * 4})`"
                  >
                    <text x="-30" y="5" fill="#000">{{3}}</text>
                    <path stroke="rgba(0,0,0,0.19)" :d="`M0.5,0V0.5H${width}.5V0`"></path>
                  </g>

                  <g
                    v-if="(maxCount === 7)"
                    class="x-axis" fill="none" :transform="`translate(0, ${(height / 7) * 2})`"
                  >
                    <text x="-30" y="5" fill="#000">{{5}}</text>
                    <path stroke="rgba(0,0,0,0.19)" :d="`M0.5,0V0.5H${width}.5V0`"></path>
                  </g>

                  <g
                    v-if="(maxCount === 5)"
                    class="x-axis" fill="none" :transform="`translate(0, ${(height / 5) * 2})`"
                  >
                    <text x="-30" y="5" fill="#000">{{3}}</text>
                    <path stroke="rgba(0,0,0,0.19)" :d="`M0.5,0V0.5H${width}.5V0`"></path>
                  </g>

                  <g
                    v-if="(maxCount === 5)"
                    class="x-axis" fill="none" :transform="`translate(0, ${(height / 5) * 4})`"
                  >
                    <text x="-30" y="5" fill="#000">{{1}}</text>
                    <path stroke="rgba(0,0,0,0.19)" :d="`M0.5,0V0.5H${width}.5V0`"></path>
                  </g>

                  <g class="x-axis" fill="none" :transform="`translate(0, ${0})`">
                    <text data-v-190e892b="" x="-30" y="5" class="" fill="#000">{{maxCount}}</text>
                    <path stroke="rgba(0,0,0,0.19)" :d="`M0.5,0V0.5H${width}.5V0`"></path>
                  </g>
                  <g class="x-axis" fill="none" :transform="`translate(0, ${height})`">
                    <text data-v-190e892b="" x="-20" y="5" class="" fill="#000">0</text>
                    <path stroke="rgba(0,0,0,0.19)" :d="`M0.5,6V0.5H${width}.5V6`"></path>
                      <g
                        class="tick"
                        opacity="1"
                        font-size="10"
                        font-family="sans-serif"
                        text-anchor="middle"
                        v-for="(bar, index) in bars"
                        :key="index"
                        :transform="`translate(${bar.x + bar.width + 5}, 0)`"
                      >
                        <line stroke="rgba(0,0,0,0.19)" y2="6"></line>
                        <text 
                          :transform="`rotate(-45)`"
                          x="-20"
                          text-anchor="end"
                          fill="currentColor" y="-5" dy="0.71em"
                        >{{ bar.xLabel }}</text>
                      </g>
                  </g>
                  <g class="bars" fill="none">
                    <rect
                      v-for="(bar, index) in bars"
                      fill="rgba(3, 192, 252, 0.24)"
                      :key="index"
                      :height="bar.height"
                      :width="bar.width"
                      :x="bar.x"
                      :y="bar.y"
                      v-on:mouseover="showTooltip($event, bar, 'bar')"
                      v-on:mouseout="hideTooltip($event, 'bar')"
                    >
                    </rect>
                    <text 
                       v-for="(bar, index) in bars"
                      :key="'text' + index"
                      fill="currentColor" 
                      :x="bar.x + 5"
                      :y="bar.y - 20"
                      dy="0.71em"
                      v-on:mouseover="showTooltip($event, bar, 'label')"
                      v-on:mouseout="hideTooltip($event, 'label')"

                    >{{ bar.val }}</text>
                  </g>
                </g>
              </svg>

            </div>
          </div>
      </div>

      <div class="dialog-buttons">
        <div class="default-buttons">
          <button class="new-window" @click="openNewWindow">
            <span>Open In New Window</span>
          </button>
          <button class="done" @click="closeDialog">
            <span>Done</span>
          </button>
        </div>
      </div>
    </div>
</template>

<script>
import * as d3 from "d3";
import {scaleBand, scaleLinear} from "d3";
import { mapState, mapActions } from 'vuex'; //mapGetters, 

export default {
  name: 'PacketLengths',
  props: {
    closeDialog: Function,
    fileName: String,
    isWindow: Boolean
  },
  data () {
    return {
      title: 'Packets length in ',
      filename: this.fileName || 'voip-extension.pcap',
      width: 398,//477,
      height: 260,//287,
      dataSet: [],
      maxCount: 0
    }
  },
  computed: {
    windowRoute() {
      return this.$router.history.current.params.window
    },
    ...mapState({
      dialogContent: state => state.packets.dialogContent,
      currentDialog: state => state.views.currentDialog,
      uuid: state => state.packets.uuid
    }),
    dialogContentProps() {
      let listedProps = this.dialogContent && Object.entries(this.dialogContent);
      return listedProps[0] && listedProps[0][1];
    },
    x() {
      return scaleBand()
        .range([0, this.width])
        .padding(0.3)
        .domain(this.dataSet.map(e => e[0]));
    },
    y() {
      // let values = this.dataSet.map(e => e[4]);
      return scaleLinear()
        .range([this.height, 0])
        .domain([0, this.maxCount]); //[0, Math.max(...values)]
    },
    bars() {
      let bars = this.dataSet.map(d => {

        if (d[1].avg === 0) {
          d[1].avg = null
          d[1].min = null
          d[1].max = null
          d[1].count = 0
        }

        return {
          xLabel: d[0],
          x: this.x(d[0]),
          y: this.y(d[1].count),
          val: d[1].count,
          average: d[1].avg && d[1].avg.toFixed(2),
          min: d[1].min,
          max: d[1].max,
          width: this.x.bandwidth(),
          height: this.height - this.y(d[1].count)
        };
      });
      return bars;
    },
  },
  watch: {
      dialogContentProps(newValue) {
        if (newValue) {
          this.dataSet = Object.entries(newValue).map(arr => [arr[0], arr[1]]);
        }
      },
      dataSet(newValue) {
         let maxArrValue = Math.max.apply(Math, newValue.map((a) => a[1].count ));
         if (maxArrValue >= 1000) {
           this.maxCount =  Math.ceil(maxArrValue/1000)*1000;
         }
         if (maxArrValue >= 100) {
           this.maxCount =  Math.ceil(maxArrValue/100)*100;
         }
         if ((maxArrValue <= 100) && (maxArrValue > 10)) {
            this.maxCount =  Math.ceil(maxArrValue/10)*10;
         }
         if (maxArrValue <= 10) {
          this.maxCount = Math.ceil(maxArrValue);
         }
      }
  },
  methods: {
    showTooltip(ev, bar, type) {
      const g = d3
      .select("#packet-lengths-graph");

      if (bar.val > 0) {
        g.append("div")
        .attr("class", "tooltip")
        .attr('style', `position: absolute;
          width: 100%;
          max-width: max-content;
          height: 120px;
          background: rgba(0,0,0,0.7);
          left: ${ev.offsetX - 20}px;
          top: ${this.isWindow ? (ev.offsetY + 20) : (ev.offsetY + 130)}px;
          right: 0;
          bottom: 0;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          display: flex;
          flex-direction: column;
          text-align: left;
          align-items: flex-start;
          justify-content: center;
          white-space: pre-line;
          padding: 0 10px;
          line-height: 0.45;
          border-radius: 6px;
          `)
        .text(`
          ${bar.val} Packets 
          \n
          Lengths: ${bar.xLabel}
          \n
          Average: ${bar.average}
          \n
          Min: ${bar.min}
          \n
          Max: ${bar.max}
          `);

        type === 'bar' && ev.target.setAttribute('fill', 'rgba(3, 192, 252, 1)');
      }
    },
    hideTooltip (ev, type) {
      const g = d3.select("#packet-lengths-graph");
      g.selectAll('.tooltip').remove();
      type === 'bar' && ev.target.setAttribute('fill', 'rgba(3, 192, 252, 0.24)');
    },
    ...mapActions({
      loadDialog: 'packets/loadDialogData',
      toggleWindow: 'views/toggleWindow'
    }),
    openNewWindow() {
      // let routeData = this.uuid && this.$router.resolve({path: `window/${this.uuid}/${this.$options.name}`}); //query: {searchField: this.searchField}

      let routeLink = `${window.location.origin}/window/${this.uuid}/${this.$options.name}`
      window.open(routeLink, '_blank');
      console.log(this.$options.name)
      this.toggleWindow({value: this.$options.name});
      // setTimeout(() => this.closeDialog(), 3000);
    },
  },
  mounted() {
    if (this.dialogContentProps && this.dialogContentProps.length > 0) {
      this.dataSet = Object.entries(this.dialogContentProps);
    }
    if (this.$router.history.current.name === 'window') {
      this.loadDialog({value: this.windowRoute});
      this.toggleWindow({ value: this.$options.name });
    }
  },
}
</script>

<style scoped>
.packet-lengths {
  max-width: 700px;
  max-height: 590px;
  width: 100%;
  height: 100%;
  top: 60px;
}

.packet-lengths.window {
  height: 100%;
}
.packet-lengths.window .content-wrapper {
  height: auto;
}

.packet-lengths.window .content-wrapper .content {
  overflow-y: auto;
}


.packet-lengths.window .content-wrapper .content #packet-lengths-graph {
  max-width: 850px;
  margin-bottom: 20px;
  position: relative;
}

/* .packet-lengths.window .content-wrapper .content #packet-lengths-graph .tooltip {
  margin-top: -350px !important;
} */

.content-wrapper {
    display: flex;
    flex-direction: column;
    height: 70%;
    margin-top: 5px;
}

.content-wrapper .content {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    overflow-y: auto;
}

.content-wrapper .content::-webkit-scrollbar-track {
  background: transparent;
}

.content-wrapper .content #packet-lengths-graph {
    border: 1px solid rgba(3, 192, 252, 0.25);
    width: 100%;
    /* min-width: 667px; */
    /* max-width: 850px; */
    max-width: 550px;
}

.content-wrapper .additonal-description {
    font-size: 11px;
    margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .content-wrapper .content {
    justify-content: initial;
  }
}

.new-window {
  cursor: pointer;
}
.done {
  cursor: pointer;
}
</style>
