<template>
    <div class="tab-options-list">
        <div
            class="tab-option"
            v-bind:class="option.value"
            v-for="(option, optInd) in tabOptionsList"
            v-bind:key="option + optInd"
            v-bind:index="optInd"
            v-on:click="openTabOption(option, optInd)"
        >
            <span class="option-icon"></span>{{option.label}}
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'; //, mapGetters

export default {
  name: 'OptionsList',
  props: {
    optionsList: Array
  },
  data () {
      return {
          tabOptionsList: this.optionsList,
      }
  },
  computed: {
    ...mapState({
      uuid: state => state.packets.uuid
    }),
  },
  methods: {
    openTabOption (option, ind) {

      if (this.uuid) {
        this.openedOption = (ind === this.openedOption) ? -1 : ind;

        (option.value === 'GraphsDialog' ||
        option.value === 'ProtocolLadderView') ?
            this.openStaticDialog(option) :
            this.openDialog(option);
      }

    },
    ...mapActions({
        openDialog: 'packets/loadDialogData',
        openStaticDialog: 'views/toggleDialog',
    }),
  }
}
</script>

<style scoped>
.filter-button .tab-options-list {
    display: none;

    flex-direction: column;
    align-items: flex-start;

    position: absolute;
    top: calc(100% + 8px);
    background: #fff;
    width: 196px;
    border-radius: 6px;
    border: 0.5px solid #03C0FC;
    color: #03C0FC;
    z-index: 10;
}
.filter-button.active .tab-options-list {
    display: flex;
    animation: optionexpand 0.1s linear forwards;
}
.filter-button.saved-filters.active .tab-options-list,
.filter-button.saved-filters.unactive .tab-options-list  {
    display: none;
}
.filter-button.unactive .tab-options-list {
    display: flex;
    animation: optionrelease 0.1s linear forwards;
}
.filter-button .tab-options-list .tab-option {
    padding: 8px 11px;
    font-size: 14px;
    /* width: calc(100% - (11px * 2)); */
    text-align: left;
    text-transform: capitalize;
    display: flex;
    cursor: pointer;
}
.filter-button .tab-options-list .tab-option:hover {
    background: #03C0FC;
    color: #fff;
    width: 100%;
}
.tab-options-list .tab-option {
    position: relative;
}
.filter-button .tab-options-list .tab-option .option-icon {
    display: flex;
    text-indent: -9999px;
    width: 16px;
    height: 16px;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 16px 16px;
    mask-size: 16px 16px;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    margin-right: 5px;
}

.filter-button.active .tab-options-list .option-icon {
    transform: none;
}

.DNSActivity  .option-icon {
    -webkit-mask-image: url('../../../assets/network-pc.png');
    mask-image: url('../../../assets/network-pc.png');
    background-color: #03C0FC;
}
.FollowUDPStream  .option-icon {
    -webkit-mask-image: url('../../../assets/chain.png');
    mask-image: url('../../../assets/chain.png');
    background-color: #03C0FC;
}
.ThreatAssesment  .option-icon {
    -webkit-mask-image: url('../../../assets/shield.png');
    mask-image: url('../../../assets/shield.png');
    background-color: #03C0FC;
}
.VoIPCalls  .option-icon {
    -webkit-mask-image: url('../../../assets/phone.png');
    mask-image: url('../../../assets/phone.png');
    background-color: #03C0FC;
}
.WirelessNetworks  .option-icon {
    -webkit-mask-image: url('../../../assets/satellite.png');
    mask-image: url('../../../assets/satellite.png');
    background-color: #03C0FC;
}
.ZeekLogs  .option-icon {
    -webkit-mask-image: url('../../../assets/z.png');
    mask-image: url('../../../assets/z.png');
    background-color: #03C0FC;
}
.GeoIPWorldMap  .option-icon {
    -webkit-mask-image: url('../../../assets/world.png');
    mask-image: url('../../../assets/world.png');
    background-color: #03C0FC;
}
.HTTPAnalysis  .option-icon {
    -webkit-mask-image: url('../../../assets/connections.png');
    mask-image: url('../../../assets/connections.png');
    background-color: #03C0FC;
}
.NetworkEndpoints  .option-icon {
    -webkit-mask-image: url('../../../assets/network.png');
    mask-image: url('../../../assets/network.png');
    background-color: #03C0FC;
}
.PacketLengths  .option-icon {
    -webkit-mask-image: url('../../../assets/bar-chart.png');
    mask-image: url('../../../assets/bar-chart.png');
    background-color: #03C0FC;
}
.ProtocolConversations  .option-icon {
    -webkit-mask-image: url('../../../assets/chat-.png');
    mask-image: url('../../../assets/chat-.png');
    background-color: #03C0FC;
}
.ProtocolHierarchyStats  .option-icon {
    -webkit-mask-image: url('../../../assets/tree.png');
    mask-image: url('../../../assets/tree.png');
    background-color: #03C0FC;
}
.ProtocolLadderView  .option-icon {
    -webkit-mask-image: url('../../../assets/ladder.png');
    mask-image: url('../../../assets/ladder.png');
    background-color: #03C0FC;
}
.RTPStreams  .option-icon {
    -webkit-mask-image: url('../../../assets/headphone.png');
    mask-image: url('../../../assets/headphone.png');
    background-color: #03C0FC;
}

.http  .option-icon {
    -webkit-mask-image: url('../../../assets/chain.png');
    mask-image: url('../../../assets/chain.png');
    background-color: #03C0FC;
}

.ssl  .option-icon {
    -webkit-mask-image: url('../../../assets/unlock.png');
    mask-image: url('../../../assets/unlock.png');
    background-color: #03C0FC;
}

@keyframes optionrelease {
    from {
        height: max-content;
        pointer-events: auto;
        opacity: 1;
    }
    to {
        height: 0;
        pointer-events: none;
        opacity: 0;
    }
}
@keyframes optionexpand {
    from {
        height: 0;
        pointer-events: none;
        opacity: 0;
    }
    to {
        height: max-content;
        pointer-events: auto;
        opacity: 1;
    }
}
@media screen and (max-width: 767px) {
    .exports .tab-options-list {
        right: 0;
    }
}
</style>
