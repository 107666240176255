<template>
  <div class="dialog-body file-details" v-bind:class="{ download: isDownload }">

    <div class="dialog-header">

      <div class="title-section">

        <div class="header-title">
          <!-- <button
                v-for="(btn, indx) in titleButtons"
                v-bind:key="btn.label + indx"
                v-bind:class="{active: indx === selectedSection }"
                v-on:click="selectSection(indx)"
            >{{btn.label}}</button> -->
          <button class="active">File Info</button>
        </div>

      </div>

      <div class="close-section">
        <button class="close-dialog" v-on:click="closeDialog">
          <span></span>
        </button>
      </div>

    </div>
    <!--             v-if="selectedSection === 0" -->
    <div class="content-wrapper">
      <div class="content">
        <div class="file-info-list">
          <div v-for="(item, indx) in fileDetailsList" v-bind:key="'file-details-list' + indx" class="list-item">
            <span class="item-key">{{ item.label }}</span>:&nbsp;
            <span class="item-value">{{ item.value }}</span>
          </div>
        </div>

        <!-- <div class="graph-container">
                <svg class="file-frequency" :width="width" :height="height"></svg>
              </div> -->

        <div class="content-buttons">
          <button @click="closeDialog">View Capture</button>
<!--          <button>-->
          <a :href="downloadFile" download>Download</a>
<!--          </button>-->
<!--          <button v-on:click="downloadLocalFile">Download</button>-->
        </div>
      </div>

      <!-- <div
            v-if="selectedSection === 1"
            class="content tags"
          >
            <div class="tags-header">Tagging voip-extension.pcap</div>
            <div class="tags">
              <button class="tag">
                <span class="icon"></span>
                <span>Sip</span>
              </button>
              <button class="tag">
                <span class="icon"></span>
                <span>Exemple</span>
              </button>
            </div>
          </div>

          <div
            v-if="selectedSection === 2"
            class="content comments"
          >
            <div class="comments-header">There are no packet annotations yet. You can add some to individual packets by clicking the blue dot on each line and typing your comments.
            </div>
            <div>User Comments:</div>
            <textarea></textarea>
          </div>


          <div
            v-if="selectedSection === 3"
            class="content share"
          >
            <div class="share-header">
              Only the file's owner can modify permissions.
            </div>
            <div class="share-info">
              <span class="sub-header">Group Access</span>
              There is no group access turned on for this file.
              <span class="sub-header">Share with Guests</span>
              The file is public
              <span class="sub-header">Direct Access</span>

              <span>
                URL: <a href="https://www.cloudshark.org/captures/74259932d9a7">
              https://www.cloudshark.org/captures/74259932d9a7</a>
              </span>
            </div>
          </div> -->
    </div>

    <div class="dialog-buttons">

      <div class="default-buttons">

        <div class="warn-description">
          <!-- <span class="icon"></span>
            <span>File is Read-Only - Changes will not be saved</span> -->
        </div>
        <button class="done" v-on:click="closeDialog">
          <span class="icon"></span>
          <span>Close</span>
        </button>

      </div>
    </div>

    <!-- <div
       v-if="isDownload"
       class="dialog-body download-content">

          <div class="dialog-header">

            <div class="title-section">

            <div class="header-title">
                Download {{uploadedFile.fileName}}
            </div>

            </div>

            <div class="close-section">
            <button
                class="close-dialog"
                v-on:click="closeDialog"
            >
                <span></span>
            </button>
            </div>

        </div>



        <div class="content-wrapper">
            <div class="content">
                CloudShark retains the originally uploaded file which may be retrieved unaltered. You may also export a pcapng formatted file that includes all the annotations and comments added by CloudShark users.
                <div class="file-section">
                    File selection:

                    <label>
                      <input type="radio" />
                      Export a new pcapng with CloudShark comments and annotations
                    </label>
                    <label>
                      <input type="radio" />
                      Download the original file
                    </label>
                </div>
            </div>
        </div>


        <div class="dialog-buttons">

            <div class="default-buttons">

                <button
                    class="download-file"
                    v-on:click="downloadLocalFile"
                >
                    <span class="icon"></span>
                    <span>Download file</span>
                </button>

                <div
                    class="cancel"
                    v-on:click="closeDialog"
                >
                    <span>Cancel</span>
                </div>

            </div>
        </div>

      </div> -->
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {api_pcap} from "@/services/api"; //, mapActions
// import * as d3 from "d3";

export default {
  name: 'FileDetails',
  data() {
    return {
      fileDetailsList: [],
      fileInfoTags: [
        {
          label: 'File Name',
          code: 'fileName',
          value: ''
        },
        // {
        //   label: 'Views',
        //   value: ''
        // },
        // {
        //   label: 'Uploaded at',
        //   value: ''
        // },
        {
          label: 'Start time',
          code: 'startTime',
          value: ''
        },
        {
          label: 'End time',
          code: 'endTime',
          value: ''
        },
        {
          label: 'Capture duration (seconds)',
          code: 'captureDuration',
          value: ''
        },
        {
          label: 'Number of packets',
          code: 'numberOfPackets',
          value: ''
        },
        {
          label: 'File size (bytes)',
          code: 'fileSize',
          value: ''
        },
        {
          label: 'Data size (bytes)',
          code: 'dataSize',
          value: ''
        },
        {
          label: 'File type',
          code: 'fileType',
          value: ''
        },
        {
          label: 'File encapsulation',
          code: 'fileEncapsulation',
          value: ''
        },
        {
          label: 'Average packet size (bytes)',
          code: 'averagePacketSize',
          value: ''
        },
        {
          label: 'Average packet rate (packets/sec)',
          code: 'averagePacketRate',
          value: ''
        },
        {
          label: 'Data bit rate (bits/sec)',
          code: 'dataBitRate',
          value: ''
        },
        {
          label: 'Data byte rate (bytes/sec)',
          code: 'dataByteRate',
          value: ''
        },
        // {
        //   label: 'Truncated',
        //   value: ''
        // },
        {
          label: 'File on Disk',
          code: 'fileOnDisk',
          value: ''
        },
        {
          label: 'SHA-1 Hash',
          code: 'md5',
          value: ''
        }
        // {
        //   label: 'Source',
        //   code: 'upload',
        //   value: ''
        // },
        // {
        //   label: 'Sessions using this file',
        //   value: ''
        // }
      ],
      // width: 669,
      // height: 443,
      // selectedSection: 0,
      // titleButtons: [
      //   {
      //     label: 'File Info',
      //   },
      //   {
      //     label: 'Tags',
      //   },
      //   {
      //     label: 'Comments & Annotations',
      //   },
      //   {
      //     label: 'Sharing',
      //   }
      // ],
      isDownload: false,
      downloadData: {},
    }
  },
  props: {
    closeDialog: Function,
  },
  computed: {
    ...mapGetters('packets', {
      uploadedFile: 'uploadedFileFormat',
      // dataSet: 'fullPacketListFrequency',
    }),
    ...mapState({
      localFile: state => state.packets.localFile,
      uuid: state => state.packets.uuid,
    }),
    downloadFile() {
      return `${api_pcap}/${this.uuid}/download`
    }
  },
  mounted() {
    let fileDetails = Object.entries(this.uploadedFile);
    console.log(this.uploadedFile)

    this.fileDetailsList = this.fileInfoTags.map(item => {
      const innerItem = fileDetails.find(innerItem => item.code === innerItem[0])
      return {
        ...item,
        value: innerItem[1]
      }
    })
  },
  methods: {
    selectSection(indx) {
      console.log(indx);
      this.selectedSection = indx;
    },
    // openDownload() {
    //     this.isDownload = true;
    // },

    /*
    drawFrequencies() {
        let margin = {top: 2, right: 1, bottom: 0, left: 7},
            width = 278 - (margin.left + margin.right),
            height = 75;

        const parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S.%L");

        // set the ranges
        let x = d3.scaleTime().range([0, width]);
        let y = d3.scaleLinear().range([height, 0]);
        // define the area
        const area = d3.area()
            .x(function(d) { return x(parseTime(d.time)); })
            .y0(height)
            .y1(function(d) { return y(d.counter); });

        // define the line
        const valueline = d3.line()
            .x(function(d) { return x(parseTime(d.time)); })
            .y(function(d) { return y(d.counter); });

        const svg = d3.select(".file-frequency")
            .attr("width", width)
            .attr("height", height + margin.top + margin.bottom)
        .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        // get the data
        // const dataLoader = d3.data(this.dataSet);

        // dataLoader.then(dataFiltered => {
        let dataFiltered = this.dataSet;

            console.log(dataFiltered);

            // scale the range of the data
            x.domain(d3.extent(dataFiltered, function(d) { console.log(parseTime(d.time)); return parseTime(d.time); }));
            y.domain([0, d3.max(dataFiltered, function(d) { return d.counter; })]);

            // add the area
            svg.append("path")
                .data([dataFiltered])
                .attr("class", "area")
                .attr("d", area)
                .attr("fill", "rgb(86, 158, 214)");

            // add the valueline path.
            svg.append("path")
                .data([dataFiltered])
                .attr("class", "line")
                .attr("d", valueline)
                .attr("fill", "transparent");//rgb(86, 158, 214)

        // }).catch(e => console.log(e));
    }
    */
  }
}
</script>

<style scoped>
.file-info {
  max-width: 719px;
  max-height: 269px;
  width: 100%;
  height: 100%;
}

.dialog-body {
  width: 50vw;
  top: 30px;
}

.file-details.download .dialog-header,
.file-details.download .content-wrapper,
.file-details.download .dialog-buttons {
  display: none;
}

.file-details.download .download-content .dialog-header,
.file-details.download .download-content .content-wrapper,
.file-details.download .download-content .dialog-buttons {
  display: flex;
}

.download-content .content {
  overflow-y: auto !important;
  overflow: auto;
  margin-top: 20px;
  width: 100%;
  color: #000 !important;
}

.download-content .file-wrapper {
  display: flex;
  margin-top: 50px;
  flex-direction: column;
}

.download-content .title-section {
  justify-content: flex-start;
  font-weight: 600;

}

.download-content .dialog-buttons .default-buttons {
  width: 160px;
  color: #03C0FC;
}

.file-details .default-buttons .warn-description {
  color: #cd0a0a;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.file-details .default-buttons .warn-description .icon {
  width: 16px;
  height: 16px;
  display: flex;
  background-image: url('../../../assets/icons_set.png');
  background-position: -192px -96px;
}

.download-content .dialog-buttons .default-buttons button,
.download-content .dialog-buttons .default-buttons .cancel {
  cursor: pointer;
}

.file-details .title-section::after {
  display: none;
}

.download-content .file-section {
  display: flex;
  margin-top: 50px;
  flex-direction: column;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 5px;
}

.content-wrapper .content {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.41);
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 70vh;
  align-items: center;
}

.content-wrapper .content .file-info-list {
  margin: 20px auto;
  width: 100%;
}

.content-wrapper .content .file-details-list {}

.content-wrapper .content .list-item {
  text-align: center;
  font-size: 14px;
  padding: 4px;
  color: #000;
  display: flex;
}

.content-wrapper .content .list-item:nth-child(3),
.content-wrapper .content .list-item:nth-child(13) {
  margin-bottom: 20px;
}

.content-wrapper .content .item-key {
  display: flex;
  flex-basis: 43%;
  justify-content: flex-end;
}

.content-wrapper .content .item-value {
  display: flex;
  flex-basis: 49%;
  justify-content: flex-start;
  margin-left: 10px;
}

.file-details .content.tags {
  justify-content: space-evenly;
  align-items: flex-start;
  height: 20vh;
  padding-left: 5%;
}

.file-details .content.tags .tags-header {
  color: #000;
  font-weight: 600;
}

.file-details .content.tags .tags .tag {
  border: 0.5px solid #03C0FC;
  border-radius: 6px;
  background: none;
  color: #03C0FC;
  height: 35px;
  margin-right: 10px;
}

.file-details .content.comments {
  align-items: flex-start;
  height: 60vh;
  color: #000;
  justify-content: space-evenly;
}

.file-details .content.comments textarea {
  width: 99%;
  height: 300px;
  resize: none;
}

.file-details .content.share {
  align-items: flex-start;
  height: 60vh;
  color: #000;
}

.file-details .content.share .share-header {
  margin-top: 20px;
  width: 99%;
  text-align: center;
  border: 1px solid #f9dd34;
  background: #ffef8f;
}

.file-details .content.share .share-info {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  height: 50%;
  width: 99%;
  justify-content: space-around;
}

.file-details .content.share .sub-header {
  font-weight: 600;
}

.file-details .default-buttons {
  border-top: 1px solid #dfdfdf;
  padding-top: 20px;
  margin-top: 20px;
  width: 100%;
  align-items: center;
  margin-left: 5%;
}

.file-details .title-section {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}

.file-details .title-section::before {
  content: " ";
  height: 1px;
  width: 100%;
  position: absolute;
  background: #03C0FC;
  margin: auto;
  top: 0;
  bottom: 0;
}

.file-details .title-section .header-title {
  z-index: 1;
}

.file-details .title-section .header-title button {
  border: 0.5px solid #03C0FC;
  background: #fff;
  color: #03C0FC;
  height: 35px;
  cursor: pointer;
}

.file-details .title-section .header-title button.active {
  background: #03C0FC;
  color: #fff;
  padding: 0 10px 0 10px;
}

.file-details .title-section .header-title button:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.file-details .title-section .header-title button:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.file-details .content .graph-container {
  width: 270px;
  height: 75px;
  margin: 20px 0;
}

.content-wrapper .content .content-buttons button {
  display: inline-block;
  border: 0.5px solid #03C0FC;
  border-radius: 6px;
  background: #fff;
  color: #03C0FC;
  cursor: pointer;
  margin-left: 10px;
  padding: 10px;
}
.content-wrapper .content .content-buttons a {
  all: unset;
  border: 0.5px solid #03C0FC;
  border-radius: 6px;
  background: #fff;
  color: #03C0FC;
  cursor: pointer;
  margin-left: 10px;
  padding: 10px;
}

file-details {
  top: 30px;
  width: 55vw;
}

@media screen and (max-width: 767px) {
  .content-wrapper .content {
    height: 80%;
    margin-top: 5%;
  }
}
</style>
