<template>
  <div>
    <div class="about-section">
      <div class="container narrow">
      <h2>About SIPshark.org</h2>
      <p>
        SIPshark.org is the fastest way to share a SIP pcap file without needing
        to download or install anything. It’s designed with your workflow in
        mind by using only a minimum number of keypresses to share your SIP pcap
        files.
      </p>
      </div>
    </div>
    <div class="about-list">
      <div class="container narrow">
        <div class="about-list-item">
          <h2 class="main-title">Convenient</h2>
          <div class="content">
            <div>
              <img src="../../assets/share_files.svg" alt="" />
            </div>
            <div class="list-description">
              Share files quickly, easily and worry-free.<br /><br />
              Share any type of file you like, with anyone, anywhere in the
              world. Generous data transfer rates and file size limits ensure
              even big files can be shared expeditiously. <br /><br />We're
              trying to keep it simple. Whether you prefer to send files from
              your browser or via our REST API, you will find the experience to
              be as easy and streamlined as possible.<br /><br />
              Once you have uploaded your files and shared the link, you can
              rest easy knowing that the file will not linger indefinitely in
              the cloud. As soon as it has been received by the intended
              recipient, your file is gone forever!
            </div>
          </div>
        </div>
        <div class="about-list-item">
          <h2 class="main-title">Anonymous</h2>
          <div class="content">
            <div class="list-description">
              We don't track you. We don't track your data.<br /><br />
              We value privacy and we know that you do, too. Our focus is on
              providing a cool file sharing service, not aggregating or selling
              your personal data for profit.<br /><br />
              Users are not required to create an account or provide any
              personal information in order to upload or download files.<br /><br />
              Our server log files contain no personal identifying information.
              All uploaded files are permanently deleted once they have been
              downloaded or reached their expiration date. We do not maintain
              backups of shared files.
            </div>
            <div>
              <img src="../../assets/anonymous.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="about-list-item">
          <h2 class="main-title">Secure</h2>
          <div class="content">
            <div>
              <img src="../../assets/secure_files.png" alt="" />
            </div>
            <div class="list-description">
              Your files are safe with us.<br /><br />
              Your data is encrypted every step of the way. All communications
              to, from and among our servers including file uploads, file
              downloads and API requests is encrypted via HTTPS/TLS. In
              addition, as your uploaded file data is saved to our servers, it
              is re-secured using military-grade encryption.<br /><br />
              Maintaining data security requires constant vigilance. We perform
              regular reviews of our security infrastructure and apply patches
              and upgrades immediately as required. All staff are required to
              undergo regular training to be sure that they understand and abide
              by security policies and best practices.<br /><br />
              Sharing your files with sipshark.org is also inherently more secure
              when compared to sending the files via email, chat or other cloud
              storage solutions. Since files are automatically deleted once they
              are downloaded or expired, there is no forgotten data hanging
              around in the cloud. The securest data is the data that's not even
              there!
            </div>
          </div>
        </div>
        <div class="about-list-item">
          <h2 class="main-title">FREE!</h2>
          <div class="content">
            <div class="list-description">
              Maybe even better than free.<br /><br />
              Sipshark.org is free to use with no gotchas and no hidden fees. No
              account or credit card is required to get started. Simply upload
              your files and share the link. Your wallet will be
              none-the-lighter!<br /><br />
              Just so we’re all 100% clear here: When you share a file, data is
              being transferred between your computer or phone and our servers,
              then from our servers to your friend’s device. The cost of that
              data transfer is covered by your internet service or the data plan
              on your phone. Unfortunately, we can’t make that part free. If
              only we could, we surely would!<br /><br />
              On the other hand, using sipshark.org can actually save you money. When
              you use sipshark.org, you avoid storing the file you’re sending in your
              cloud storage or mail server. Files transferred the old-fashioned
              way tend to pile up and be forgotten about, eventually costing you
              money in data storage fees. Why pay storage fees for a file that
              you just wanted to send to a friend or colleague?<br /><br />
              Also, many team chat apps have limits to the size of files that
              can be transferred within their apps. Using sipshark.org, you can
              circumvent those limits without paying to upgrade your team chat
              service.
            </div>
            <div>
              <img src="../../assets/file_bundle.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
};
</script>

<style>
.about-section {
  text-align: center;
}
.about-list {
  padding: 50px;
  position: relative;
  background: #f7fafa;
  border-radius: 300px 300px 0px 0px;
  width: auto;
}
.about-list-item {
  text-align: center;
  margin-bottom: 70px;
}
.about-list-item:last-child {
  margin-bottom: 0px;
}
.about-list-item .list-description {
  font-size: 14px;
  color: #5c696a;
  font-weight: 400;
  text-align: left;
}
.about-list-item .content {
  display: flex;
  align-items: center;
}
.about-list-item .content div:first-child {
  margin-right: 60px;
}
@media (max-width: 850px) {
  .about-section p{
    margin-bottom: 40px;
  }
  .about-list-item .content img {
    width: 215px;
  }
  .about-list {
    padding: 50px 20px;
    border-radius: 150px 150px 0px 0px;
  }
}
@media (max-width: 650px) {
  .about-list-item .content {
    flex-direction: column;
  }
  .about-list-item .content img {
    order: 1;
  }
  .about-list-item .list-description{
    margin-top: 20px;
    order: 2;
  }
  .about-list-item{
    margin-bottom: 40px;
  }
  .about-list-item .content div:first-child{
    margin-right: 0;
  }
}
@media (max-width: 650px) {
  .about-list {
    padding: 40px 10px;
    border-radius: 100px 100px 0px 0px;
  }
}
</style>
