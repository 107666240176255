<template>
  <div>
    <Header v-if="this.routeName !== 'main'" />
    <router-view></router-view>
    <Footer v-if="this.routeName !== 'main'" />
  </div>
</template>

<script>
import Header from './components/shared/MainHeader.vue';
import Footer from './components/shared/Footer.vue';

export default {
  name: 'App',
  components:{
    Header,
    Footer
  },
  data() {
    return {
      currentRoute: this.routeName
    }
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main-title {
  font-size: 28px;
  color: #5c696a;
  font-weight: 300;
}
html, body {
  margin: 0;
  padding: 0;
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container {
  max-width: 1170px;
  margin: 0 auto;
}

textarea {
  padding: 0;
  margin: 0;
}

@media (max-width: 1200px){
  .container{
  margin-left: 40px;
  margin-right: 40px;
  }
}

@media (max-width: 450px){
  .container{
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}
.container.narrow {
  max-width: 970px;
  margin: 0 auto;
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.mr-10 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}
</style>
