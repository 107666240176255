<template>
  <div 
    class="dialog-container"
    v-if="currentDialog"
  >

    <div
      v-if="dialogLoading"
      class="loading-dialog"
    >
      <div class="animated"></div>
    </div>
    <component
      v-bind:class="{hidden: dialogLoading}"
      v-bind:is="currentDialog.value"
      v-bind:closeDialog="closeDialog"
      v-bind:fileName="uploadedFile.fileName"
     ></component>

  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

// import DNSActivity from './content/DNSActivity';
import FollowUDPStream from './content/FollowUDPStream';
// import ThreatAssesment from './content/ThreatAssesment';
import VoIPCalls from './content/VoIPCalls';
// import WirelessNetworks from './content/WirelessNetworks';
// import ZeekLogs from './content/ZeekLogs';
import GeoIPWorldMap from './content/GeoIPWorldMap';
// import HTTPAnalysis from './content/HTTPAnalysis';
import NetworkEndpoints from './content/NetworkEndpoints';
import PacketLengths from './content/PacketLengths';
import ProtocolConversations from './content/ProtocolConversations';
import ProtocolHierarchyStats from './content/ProtocolHierarchyStats';
import ProtocolLadderView from './content/ProtocolLadderView';
import RTPStreams from './content/RTPStreams';
import FileDetails from './content/FileDetails';
// import LeftPaneDialog from './content/LeftPaneDialog';
import GraphsDialog from './content/GraphsDialog';
import Profile from './content/Profile';
import GraphsStats from './content/GraphsStats';
import SIPStats from './content/SIPStats';

export default {
  name: 'MainModal',
  components: {
    // DNSActivity,
    FollowUDPStream,
    // ThreatAssesment,
    VoIPCalls,
    // WirelessNetworks,
    // ZeekLogs,
    GeoIPWorldMap,
    // HTTPAnalysis,
    NetworkEndpoints,
    PacketLengths,
    ProtocolConversations,
    ProtocolHierarchyStats,
    ProtocolLadderView,
    RTPStreams,
    FileDetails,
    // LeftPaneDialog,
    GraphsDialog,
    Profile,
    GraphsStats,
    SIPStats,
  },
  data() {
    return {
      
    }
  },
  computed: {
    ...mapState({
      currentDialog: state => state.views.currentDialog,
      dialogLoading: state => state.packets.dialogLoading
      // uploadedFile: state => state.packets.uploadedFile
    }),
    ...mapGetters('packets', {
      uploadedFile: 'uploadedFileFormat',
    }),
  },
  methods: {
    ...mapActions({
      closeDialog: 'views/toggleDialog'
    })
  }
}
</script>

<style>
.dialog-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.23);
  left: 0;
  top: 0;
  z-index: 15;
}
.dialog-container .hidden {
  visibility: hidden;
  pointer-events: none;
}
.loading-dialog {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.8);
  /* background: #fff;
  width: 90vw;
  height: 95vh; 
  border-radius: 25px;
  */
}
.loading-dialog .animated {
  background: url('../../assets/loading.gif');
  width: 100px;
  height: 100px;
  background-size: contain;
}
.dialog-body {
  position: absolute;
  left: 0;
  right: 0;
  top: 80px;
  margin: auto;
  background: #fff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}
.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 13px 18px 0;
}
.title-section {

}
.header-title {

}
.title-text {
  margin-right: 5px;
}
.file-name {
  color: #03C0FC;
  cursor: pointer;
}

.close-section {

}

.close-dialog {
  background: none;
  border: none;
  cursor: pointer;
}

.close-dialog span {
  display: flex;

  text-indent: -9999px;
  width: 27px;
  height: 27px;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url('../../assets/close_dialog_icon.svg');
  mask-image: url('../../assets/close_dialog_icon.svg');
  -webkit-mask-size: 27px 27px;
  mask-size: 27px 27px;
  background-color: #000;
}

.content-wrapper {
  margin-right: 18px;
  margin-left: 18px;
}

.dialog-buttons {
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;
  margin-bottom: 25px;
  margin-top: 25px;
}
.default-buttons {
  display: flex;
  justify-content: space-between;
  width: 257px;
}
.default-buttons button {
  border: 0.5px solid #03C0FC;
  border-radius: 6px;
  background: none;
  color: #03C0FC;
  height: 35px;
}
.new-window {
  width: 154px;
}
.done {
  width: 85px;
}
@media screen and (max-width: 767px) {
  .dialog-container {
    background: #000;
  }
}
</style>
