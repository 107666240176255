<template>
  <div class="table-stats-container">
    <div v-if="listLoading" class="loading-animated"></div>
    <table v-else>
      <thead>
        <tr style="text-align:left">
          <th class="an" @click="order">
            <div class="container">
              <span class="table-list-icon"></span>
              <div class="order-button">
                <span v-if="orderBy === 'asc'" class="order-icon order-asc"></span>
                <span v-if="orderBy === 'desc'" class="order-icon order-desc"></span>
              </div>
            </div>
          </th>
          <th class="" data-sort-type="number">
            <div>No.</div>
          </th>
          <th class="sortAsc" data-sort-type="number" v-on:click="changeTimeDisplay">
            <div>Time</div>
          </th>
          <th>Source</th>
          <th>Destination</th>
          <th v-on:click="loadFilterProtocol()">Protocol</th>
          <th>Length</th>
          <th style="flex-grow: 1; text-align: left; width: 40%;">Info</th>
        </tr>
      </thead>
      <tbody class="show-annotations">
        <tr v-if="packets.length === 0">
          <td colspan="8" style="padding: 10px; text-align: center;">No packets were found matching the current filter</td>
        </tr>
        <tr v-for="row in packetList(orderBy)" :key="row.no" v-on:click="updatePacketInfo(row.no)"
          v-bind:class="{ active: parseInt(selectedPacket) === parseInt(row.no) }">
          <td class="an">{{ row.blank }}</td>
          <td>{{ row.no }}</td>
          <td>{{ timeDisplay === 'actual' ? row.time.split('.')[0] : row.time }}</td>
          <td>{{ row.source }}</td>
          <td>{{ row.destination }}</td>
          <td>{{ row.protocol }}</td>
          <td>{{ row.length }}</td>
          <td style="flex-grow: 1; text-align: left; width: 40%;">{{ row.info }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'TableStats',
  data() {
    return {
      timeDisplay: 'actual',
      filter: 0,
      filtersList: ['', 'tcp', 'udp'],
      orderBy: ''
    }
  },
  computed: {
    ...mapState({
      packets: state => state.packets.packetList,
      selectedPacket: state => state.packets.selectedPacket,
      listLoading: state => state.packets.listLoading,
      uploadedFile: state => state.packets.uploadedFile
    }),
    ...mapGetters('packets', {
      uploadedFile: 'uploadedFileFormat',
      packetList: 'getPacketListOrderBy'
    }),
    newName() {
      return this.uploadedFile.fileName
    }
  },
  methods: {
    ...mapActions({
      loadPackets: 'packets/getPacketsList',
      // getDetails: 'packets/getPacketDetails',
      // getBytes: 'packets/getPacketBytes',
      selectPacket: 'packets/getSelectedPacket',
    }),
    destination() { },
    protocol() { },
    length() { },
    info() { },
    source() { },
    updatePacketInfo(frame) {
      console.log(frame);
      this.selectPacket(frame);
    },
    changeTimeDisplay() {
      let timeDisplay =
        (this.timeDisplay === 'actual' || !this.timeDisplay) ?
          'relative' : 'actual';
      this.loadPackets({ timeDisplay });
      this.timeDisplay = timeDisplay;
    },
    loadFilterProtocol() {
      if (this.filter < (this.filtersList.length - 1)) {
        this.filter = this.filter + 1;
      } else {
        this.filter = 0;
      }
    },
    order () {
      this.orderBy = this.orderBy === 'asc' ? 'desc' : 'asc'
    }
  },
  watch: {
    newName(newValue) {
      if (newValue) {
        this.loadPackets({ filter: '' });
      }
    },
    packets(newValue) {
      if (newValue && newValue.length !== 0) {
        this.updatePacketInfo(newValue[0].no);
      }
    },
    filter(newValue) {
      this.loadPackets({
        filter: this.filtersList[newValue]
      })
    }
  },
  mounted() {
    console.log('TableStats')
    // this.loadPackets({ filter: '' });
    // (this.packets.length > 0 && this.selectedPacket === 0) && this.updatePacketInfo(this.packets[0].no);
  }
}

</script>

<style scoped>
.loading-animated {
  background: url('../assets/loading.gif');
  width: 70px;
  height: 70px;
  background-size: contain;
  margin: auto;
}

table th {
  background: #EDEFF5;
  border-right: 0.5px solid #C4C4C4;
  color: black;
  cursor: pointer;
  padding: 8px;
  min-width: 30px;
  text-align: center;


  top: 0;
  position: sticky;
}

table tr {
  text-align: left;
}

table tr:hover td,
table tr.active td {
  background: rgba(3, 192, 252, 0.24);
}

table tr td {
  background: rgba(3, 192, 252, 0.12);
  height: 24px;
  font-size: 14px;
}

.table-stats-container {
  /* height: 35vh; */
  /* overflow-y: auto; */
  /* width: 100vw; */
  margin: auto;
  /* margin-top: 27px; */

  /* border-top-left-radius: 10px; */
  /* border-top-right-radius: 10px; */
}

table {
  width: 100%;
  min-width: 1240px;
  background: #fff;

  /* border-top-left-radius: 10px; */
  /* border-top-right-radius: 10px; */

  font-family: 'Roboto', sans-serif;
}

table tbody {
  border-collapse: collapse;
}

table tr:hover {
  cursor: pointer;
}

table td {
  padding: 0 8px;
  text-align: center;
}

table td:last-child {
  text-align: left;
}

.table-list-icon {
  display: flex;

  text-indent: -9999px;
  width: 18px;
  height: 15px;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url('../assets/table_list.svg');
  mask-image: url('../assets/table_list.svg');
  -webkit-mask-size: 18px 15px;
  mask-size: 18px 15px;
  background-color: #000;
  margin: auto;
}

table th .container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

table th .order-button {
  display: flex;
  flex-direction: column;
  width: 10px;
  margin: 0;
  align-items: center;
}
table th .order-button .order-icon {
  display: flex;
  text-indent: -9999px;
  width: 5px;
  height: 9px;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url('../assets/arrow_icon.svg');
  mask-image: url('../assets/arrow_icon.svg');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 5px 9px;
  mask-size: 5px 9px;
  background-color: #000;
}

table th .order-button .order-icon.order-asc {
  transform: rotate(270deg);
}

table th .order-button .order-icon.order-desc {
  transform: rotate(90deg);
}
</style>
