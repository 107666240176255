<template>
  <div
    class="error-page"
  >
    <div class="error-page-background"></div>
    <div class="animation"></div>
    <a 
      href="http://www.denovolab.com" class="branded-logo"
      v-on:click="emptifyError"
    ></a>
    <div class="error-article">
      <div class="header">Oops!</div>
      <div class="subheader">Where are we?</div>
      <div class="description">
        The page you are looking for was moved, removed, renamed or <br/>
might never existed.
      </div>
      <router-link to="/" @click="emptifyError">
        <a class="redirect-home">Go Home</a>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ErrorPage',
  methods: {
    ...mapActions({
      emptifyError: 'packets/emptifyError',
    }),
  }
}
</script>

<style scoped>
  .error-page-background {
    width: 100vw;
    height: 100vh;
    margin: auto;
    border: 2px solid #999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    background-color: #8DDFEA;
    background-image: url('../assets/bear.png');
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center 100%;
  }
  .branded-logo {
    position: absolute;
    background: url('../assets/logo/3-01.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100px;
    height: 50px;
    left: 0;
    top: 0;
    z-index: 99;
  }
  .animation {
    width: 100vw;
    height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    background-image: url('../assets/z-1.gif');
    background-size: 3vw;
    background-repeat: no-repeat;
    background-position: 20% 80%;
  }
  .error-article {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    position: absolute;
    left: 10%;
    top: 5%;
    z-index: 99;
  }
  .error-article .header {
    font-size: 190px;
    font-weight: 700;
    color: #222a4b;
    margin-bottom: .5rem;
  }
  .error-article .subheader {
    font-size: 50px;
    font-weight: 300;
    color: #00a9e1;
    margin-bottom: .5rem;
  }
  .error-article .description {
    font-size: 20px;
    color: #000000;
    font-weight: 300;
  }
  .redirect-home {
    font-size: 18px;
    font-weight: 300;
    color: #ffffff;
    border: 0px solid;
    border-bottom: 2px solid;
    border-color: #007ca1;
    padding: 10px 41px;
    border-radius: 5px;
    background: none;
    display: inline-block;
    margin: 10px 0;
    background-color: #00a9e1;
    text-decoration: none;
    margin-top: 1rem;
  }
  .redirect-home:hover {
    background: #0090c0;
  }
</style>
