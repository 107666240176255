<template>
  <div class="right-panel">
    <div v-if="bytesLoading" class="loading-animated"></div>
    <div v-else class="asciiPanel" id="tab-panel-0" data-tab="0">

      <!-- <div class="asciiOffset">
        0000
        0010
        0020
        0030
        0040
        0050
        0060
        0070
        0080
        0090
        00a0
        00b0
        00c0
        00d0
        00e0
        00f0
        0100
        0110
        0120
        0130
        0140
        0150
        0160
        0170
        0180
        0190
        01a0
        01b0
        01c0
        01d0
        01e0
        01f0
        0200
        0210
        0220
        0230
        0240
        0250
        0260
        0270
        0280
        0290
        02a0
        02b0
        02c0
        02d0
        02e0
        02f0
        0300
        0310
        0320
        0330
        0340
        0350
        0360
        0370
        0380
        0390
        03a0
        03b0
      </div>

      <div class="asciiHex">
        00 1e 68 2f 1b 85 00 1e  68 0e b6 6b 08 00 45 68
        03 a4 00 6b 00 00 90 11  9b 10 c0 a8 05 0a c0 a8
        05 0b 13 c4 13 c4 03 90  59 2c 49 4e 56 49 54 45
        20 73 69 70 3a 30 40 31  39 32 2e 31 36 38 2e 35
        2e 31 31 20 53 49 50 2f  32 2e 30 0d 0a 54 6f 3a
        20 22 41 75 74 6f 20 41  74 74 65 6e 64 61 6e 74
        22 3c 73 69 70 3a 30 40  31 39 32 2e 31 36 38 2e
        35 2e 31 31 3e 0d 0a 41  63 63 65 70 74 3a 20 61
        70 70 6c 69 63 61 74 69  6f 6e 2f 73 64 70 2c 74
        65 78 74 2f 70 6c 61 69  6e 2c 6d 65 73 73 61 67
        65 2f 73 69 70 66 72 61  67 2c 61 70 70 6c 69 63
        61 74 69 6f 6e 2f 73 69  70 0d 0a 55 73 65 72 2d
        41 67 65 6e 74 3a 20 59  56 35 2f 31 2e 31 2e 34
        0d 0a 56 69 61 3a 20 53  49 50 2f 32 2e 30 2f 55
        44 50 20 31 39 32 2e 31  36 38 2e 35 2e 31 30 3a
        35 30 36 30 3b 72 70 6f  72 74 3b 62 72 61 6e 63
        68 3d 7a 39 68 47 34 62  4b 38 63 36 36 36 36 31
        0d 0a 46 72 6f 6d 3a 20  22 4a 6f 68 6e 20 48 61
        6c 6c 65 72 22 3c 73 69  70 3a 32 30 31 40 31 39
        32 2e 31 36 38 2e 35 2e  31 31 3e 3b 74 61 67 3d
        34 35 64 64 61 31 66 64  0d 0a 41 6c 6c 6f 77 3a
        20 49 4e 46 4f 2c 52 45  46 45 52 2c 4e 4f 54 49
        46 59 2c 49 4e 56 49 54  45 2c 41 43 4b 2c 4f 50
        54 49 4f 4e 53 2c 42 59  45 2c 43 41 4e 43 45 4c
        0d 0a 41 6c 6c 6f 77 2d  45 76 65 6e 74 73 3a 20
        72 65 66 65 72 0d 0a 48  69 73 74 6f 72 79 2d 49
        6e 66 6f 3a 20 3c 73 69  70 3a 30 40 31 39 32 2e
        31 36 38 2e 35 2e 31 31  3e 3b 69 6e 64 65 78 3d
        31 0d 0a 43 61 6c 6c 2d  49 44 3a 20 36 37 33 62
        30 34 33 37 2d 64 63 33  66 39 39 64 64 2d 36 39
        39 66 39 39 61 37 2d 39  32 66 64 38 30 36 36 40
        31 39 32 2e 31 36 38 2e  35 2e 31 30 0d 0a 4d 61
        78 2d 46 6f 72 77 61 72  64 73 3a 20 37 30 0d 0a
        43 6f 6e 74 61 63 74 3a  20 3c 73 69 70 3a 32 30
        31 40 31 39 32 2e 31 36  38 2e 35 2e 31 30 3a 35
        30 36 30 3e 0d 0a 43 6f  6e 74 65 6e 74 2d 4c 65
        6e 67 74 68 3a 20 32 35  37 0d 0a 43 6f 6e 74 65
        6e 74 2d 54 79 70 65 3a  20 61 70 70 6c 69 63 61
        74 69 6f 6e 2f 73 64 70  0d 0a 53 75 70 70 6f 72
        74 65 64 3a 20 74 64 69  61 6c 6f 67 2c 72 65 70
        6c 61 63 65 73 2c 6e 6f  72 65 66 65 72 73 75 62
        2c 68 69 73 74 69 6e 66  6f 0d 0a 43 53 65 71 3a
        20 33 33 31 35 35 20 49  4e 56 49 54 45 0d 0a 0d
        0a 76 3d 30 0d 0a 6f 3d  69 70 72 31 45 36 38 30
        45 42 36 36 42 20 34 36  38 34 31 36 32 20 34 36
        38 34 31 36 32 20 49 4e  20 49 50 34 20 31 39 32
        2e 31 36 38 2e 35 2e 31  30 0d 0a 73 3d 2d 0d 0a
        63 3d 49 4e 20 49 50 34  20 31 39 32 2e 31 36 38
        2e 35 2e 31 30 0d 0a 74  3d 30 20 30 0d 0a 6d 3d
        61 75 64 69 6f 20 38 30  30 30 20 52 54 50 2f 41
        56 50 20 31 38 20 30 20  38 20 31 30 31 0d 0a 61
        3d 72 74 70 6d 61 70 3a  31 38 20 47 37 32 39 2f
        38 30 30 30 0d 0a 61 3d  72 74 70 6d 61 70 3a 30
        20 50 43 4d 55 2f 38 30  30 30 0d 0a 61 3d 72 74
        70 6d 61 70 3a 38 20 50  43 4d 41 2f 38 30 30 30
        0d 0a 61 3d 72 74 70 6d  61 70 3a 31 30 31 20 74
        65 6c 65 70 68 6f 6e 65  2d 65 76 65 6e 74 2f 38
        30 30 30 0d 0a 61 3d 70  74 69 6d 65 3a 32 30 0d
        0a 61 3d 66 6d 74 70 3a  31 30 31 20 30 2d 31 36
        0d 0a
      </div>

      <div class="asciiText">
        ..h/....h..k..Eh
        ...k............
        ........Y,INVITE
        &nbsp;sip:0@192.168.5
        .11&nbsp;SIP/2.0..To:
        &nbsp;"Auto&nbsp;Attendant
        "&lt;sip:0@192.168.
        5.11&gt;..Accept:&nbsp;a
        pplication/sdp,t
        ext/plain,messag
        e/sipfrag,applic
        ation/sip..User-
        Agent:&nbsp;YV5/1.1.4
        ..Via:&nbsp;SIP/2.0/U
        DP&nbsp;192.168.5.10:
        5060;rport;branc
        h=z9hG4bK8c66661
        ..From:&nbsp;"John&nbsp;Ha
        ller"&lt;sip:201@19
        2.168.5.11&gt;;tag=
        45dda1fd..Allow:
        &nbsp;INFO,REFER,NOTI
        FY,INVITE,ACK,OP
        TIONS,BYE,CANCEL
        ..Allow-Events:&nbsp;
        refer..History-I
        nfo:&nbsp;&lt;sip:0@192.
        168.5.11&gt;;index=
        1..Call-ID:&nbsp;673b
        0437-dc3f99dd-69
        9f99a7-92fd8066@
        192.168.5.10..Ma
        x-Forwards:&nbsp;70..
        Contact:&nbsp;&lt;sip:20
        1@192.168.5.10:5
        060&gt;..Content-Le
        ngth:&nbsp;257..Conte
        nt-Type:&nbsp;applica
        tion/sdp..Suppor
        ted:&nbsp;tdialog,rep
        laces,norefersub
        ,histinfo..CSeq:
        &nbsp;33155&nbsp;INVITE...
        .v=0..o=ipr1E680
        EB66B&nbsp;4684162&nbsp;46
        84162&nbsp;IN&nbsp;IP4&nbsp;192
        .168.5.10..s=-..
        c=IN&nbsp;IP4&nbsp;192.168
        .5.10..t=0&nbsp;0..m=
        audio&nbsp;8000&nbsp;RTP/A
        VP&nbsp;18&nbsp;0&nbsp;8&nbsp;101..a
        =rtpmap:18&nbsp;G729/
        8000..a=rtpmap:0
        &nbsp;PCMU/8000..a=rt
        pmap:8&nbsp;PCMA/8000
        ..a=rtpmap:101&nbsp;t
        elephone-event/8
        000..a=ptime:20.
        .a=fmtp:101&nbsp;0-16
        ..
      </div> -->

      <div v-for="(row, ind) in dataList" v-bind:key="'data-row' + ind" v-bind:row="row" class="data-line">
        {{  row  }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'RightPane',
  data() {
    return {
      dataList: []
    }
  },
  computed: {
    ...mapGetters('packets', {
      packetBytes: 'packetBytes',
      selectedPacketNum: 'getSelectedPacketNum',
    }),
    ...mapState({
      packetLists: state => state.packets.packetList,
      packetBytes: state => state.packets.packetBytes,
      selectedPacket: state => state.packets.selectedPacket,
      bytesLoading: state => state.packets.bytesLoading
    }),
    // packetBytesLength () {
    //   return this.packetBytes && this.packetBytes.length;
    // },
  },
  watch: {
    // packetLists (newValue) {
    //   if (newValue) {
    //     this.getBytes(this.selectedPacket);
    //   }
    // },
    selectedPacketNum(newValue) {
      if (newValue) {
        this.getBytes(newValue);
        console.log(newValue, 'selected');
      }
    },
    packetBytes(newValue) {
      if (newValue && newValue.length !== 0) {
        // let everyRowFormat = `${this.packetBytes.slice(0, 5)}${this.packetBytes.slice(5, 48)}  ${this.packetBytes.slice(48)}`;
        this.bytesFormatting();
      } else {
        this.dataList = [];
      }
    },
  },
  methods: {
    ...mapActions({
      getBytes: 'packets/getPacketBytes',
    }),
    bytesFormatting() {
      let everyRow = this.packetBytes.match(/.{1,72}/g);

      this.dataList = everyRow.map(er =>
        `${er.slice(0, 5)}
        ${String.fromCharCode(160)}
        ${er.slice(5, 54)}
        ${String.fromCharCode(160)}
        ${er.slice(54)}`);
    }
  },
  mounted() {
    if (this.selectedPacket) {
        console.log('RightPane')
        this.getBytes(this.selectedPacket)
      }
  }
}
</script>

<style>
.right-panel {
  position: relative;
  background-color: #fff;
  font-family: monospace;
  padding: 10px;
}

.loading-animated {
  background: url('../../assets/loading.gif');
  width: 70px;
  height: 70px;
  background-size: contain;
  margin: auto;
}

.asciiText {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 10em;
  text-align: justify;
}

.asciiHex {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 29em;
  text-align: justify;
  justify-content: space-between;
}

.asciiOffset {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 4em;
}

.asciiPanel {
  display: flex;
  flex-direction: row;
  width: 45em;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-direction: column;
}

.asciiPanel .data-line {
  width: 100%;
}
</style>
